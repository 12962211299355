import { createStore } from 'vuex'
import http from './http'
import user from './user'
import home from './home'
import news from './news'
import activities from './activities'
import store from './store'
import game from './game'
import shop from './shop'
import faq from './faq'
import shpw from './shpw'
import activity from './activity'
import tim from "./tim";

export default createStore({
  modules: {
    http,
    user,
    home,
    news,
    activities,
    store,
    game,
    shop,
    faq,
    shpw,
    activity,
    tim
  }
})
