<template>
  <div class="user-set transition-all">
    <div
      :class="`bg transition-all duration-700 ${expandContent ? 'shink' : ''} ${expandContentShort ? 'shink-sm' : ''}`"
    >
      <img src="@/assets/web/login.png" alt="" />
    </div>
    <div
      :class="`content transition-all duration-700 ${expandContent ? 'expand' : ''} ${expandContentShort ? 'expand-sm' : ''}`"
    >
      <el-icon class="close" @click="handleClose">
        <Close />
      </el-icon>

      <login
        v-if="step === '0'"
        @handleClick="handleClick"
        @handleClose="handleClose"
      ></login>
      <create
        v-if="step === '1'"
        @handleClick="handleClick"
        @handleStepChanged="handleCreateStepChanged"
      ></create>
      <forget v-if="step === '2'" @handleClick="handleClick"></forget>
      <Old v-if="step === '3'" @handleClick="handleClick"></Old>
    </div>
  </div>
</template>

<script setup>
import Login from "./login/index";
import Old from "./old/index";
import Create from "./create/index";
import Forget from "./forget/index";
import { ref, reactive } from "vue";
import { useRoute } from "vue-router";

const emit = defineEmits(["handleClose"]);

const step = ref("0");
const route = useRoute();
const expandContent = ref(false);
const expandContentShort = ref(false);

/**
 * 关闭弹窗
 */
const handleClose = () => {
  if (route.path === "/dctopup") {
    window.location.href = "/dctopup";
  }
  step.value = "0";
  expandContent.value = false;

  emit("handleClose");
};

/**
 * 切换展示（登录，注册，找回密码，老用户）
 * @param v
 */
const handleClick = (v) => {
  step.value = v;
  expandContentShort.value = v === "3";
};

// Handle when create step changed
const handleCreateStepChanged = (s) => {
  expandContent.value = s === 2;
};
</script>

<style scoped lang="scss">
@use "@/styles/base" as *;

.user-set {
  width: 100vw;
  height: 100vh;
  display: flex;
  background: #1b1f2a;

  @include media("<tablet") {
    justify-content: center;
  }

  .bg {
    position: relative;
    flex: 1.3;

    &.shink {
      flex: 1;
    }

    &.shink-sm {
      flex: 1.1;
    }

    @include media("<desktop") {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    @include media("<phone") {
      display: none;
    }

    img {
      width: 700px;
      height: 100%;

      @include media("<desktop") {
        width: 100%;
      }
    }
  }

  :deep(.close) {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    color: #ffffff;
    background: #343942;
    z-index: 3;
    cursor: pointer;

    svg {
      width: 28px;
      height: 28px;
      color: #ffb20e;
    }
  }

  :deep(.content) {
    position: relative;
    flex: 1;

    &.expand {
      flex: 3;
    }
    &.expand-sm {
      flex: 2;
    }

    @include media("<tablet") {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      /* align-self: center; */
    }
  }
}
</style>
