import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import Index from "../views/web/index.vue";
import Home from "../views/web/home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "index",
    component: Index,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
        meta: { name: "home" },
      },
      {
        path: "/games",
        name: "Games",
        component: () => import("../views/web/games/index.vue"),
        meta: { name: "games" },
      },
      {
        path: "/tournaments",
        name: "Tournaments",
        component: () => import("../views/web/tournaments/index.vue"),
        meta: { name: "tournaments" },
      },
      {
        path: "/tournaments/detail/:id",
        name: "TournamentsDetail",
        component: () => import("../views/web/tournaments/detail.vue"),
        meta: { name: "tournaments" },
      },
      {
        path: "/shop",
        name: "Shop",
        component: () => import("../views/web/shop/index.vue"),
        meta: { name: "shop" },
      },
      {
        path: "/shop/detail/:id",
        name: "shopDetail",
        component: () => import("../views/web/shop/shop-detail.vue"),
        meta: { name: "shop" },
      },
      {
        path: "/news/detail/:id/:title",
        name: "WebNewsDetail",
        component: () => import("../views/web/news/detail.vue"),
        meta: { name: "news" },
      },
      {
        path: "/news/:page?",
        name: "WebNews",
        component: () => import("../views/web/news/index.vue"),
        meta: { name: "news" },
      },
      {
        path: "/membership",
        name: "Membership",
        component: () => import("../views/web/membership/index.vue"),
        meta: { name: "membership" },
      },
      {
        path: "/cart",
        name: "Cart",
        component: () => import("../views/web/cart/index.vue"),
        meta: { name: "cart" },
      },
      {
        path: "/user",
        name: "User",
        component: () => import("../views/web/user/index.vue"),
        meta: { name: "user" },
      },
      {
        path: "/faqList",
        name: "Q&A",
        component: () => import("../views/web/faq/faq-list.vue"),
        meta: { name: "faqList" },
      },
      {
        path: "/store/:id",
        name: "Store",
        component: () => import("../views/web/store/store-index.vue"),
        meta: { name: "store" },
      },
      {
        path: "/about",
        name: "AboutUs",
        component: () => import("../views/web/about/about-us.vue"),
        meta: { name: "about" },
      },
      {
        path: "/qrcode",
        name: "QrCode",
        component: () => import("../views/web/qrcode/qrcode.vue"),
        meta: { name: "qrcode" },
      },
      {
        path: "/shpw",
        name: "Shpw",
        component: () => import("../views/web/shpw/shpw-index.vue"),
        meta: { name: "shpw" },
      },
      {
        path: "/shpw-cart",
        name: "ShpwCart",
        component: () => import("../views/web/shpw/cart/shpw-cart.vue"),
        meta: { name: "shpw" },
      },
      {
        path: "/dctopup",
        name: "Dctopup",
        component: () => import("../views/web/dctopup/dctopup-index.vue"),
        meta: { name: "shpw" },
      },
      {
        path: "/sidekick",
        name: "sidekick",
        component: () => import("../views/web/publicity/publicity-index.vue"),
        meta: { name: "sidekick" },
      },
      {
        path: "/user-setting",
        name: "user-setting",
        component: () => import("@/views/web/user-setting/user-setting.vue"),
      },
      {
        path: "/integral",
        name: "integral",
        component: () => import("../views/web/integral/index.vue"),
        meta: { name: "integral" },
      },
    ],
  },
  {
    path: "/riot-auth",
    name: "riot-auth",
    component: () => import("@/views/riot/riot.vue"),
  },
  {
    path: "/share",
    name: "Share",
    component: () => import("../views/share/share-index.vue"),
  },
  {
    path: "/transfer",
    name: "Transfer",
    component: () => import("../views/transfer/transfer.vue"),
  },
  {
    path: "/transfer-riot",
    name: "TransferRiot",
    component: () => import("@/views/transfer-riot/transfer.vue"),
  },
  {
    path: "/activity/naraka",
    name: "Naraka",
    component: () => "../views/activity/naraka.vue",
  },
  {
    path: "/sidekick/register",
    name: "sidekick-register",
    component: () => import("@/views/sidekick/register/register.vue"),
  },
  {
    path: "/h5",
    name: "home",
    component: () => import("../views/h5/home.vue"),
    redirect: "/h5/index",
    children: [
      {
        path: "/h5/index",
        name: "Index",
        component: () => import("../views/h5/index.vue"),
      },
      {
        path: "/h5/login",
        name: "Login",
        component: () => import("../views/h5/login.vue"),
      },
      {
        path: "/h5/forget",
        name: "Forget",
        component: () => import("../views/h5/forget.vue"),
      },
      {
        path: "/h5/create",
        name: "Create",
        component: () => import("../views/h5/create.vue"),
      },
      {
        path: "/h5/news",
        name: "News",
        component: () => import("../views/h5/news/News.vue"),
      },
      {
        path: "/h5/news/:id/:title",
        name: "NewsDetail",
        component: () => import("../views/h5/news/newsDetail/NewsDetail.vue"),
      },
      {
        path: "/h5/activities",
        name: "Activities",
        component: () => import("../views/h5/activities/Activities.vue"),
      },
      {
        path: "/h5/activities/:id",
        name: "ActivitiesDetail",
        component: () =>
          import(
            "../views/h5/activities/activitiesDetail/ActivitiesDetail.vue"
          ),
      },
      {
        path: "/h5/about/:id",
        name: "StoreDetail",
        component: () =>
          import("../views/h5/store/storeDetail/StoreDetail.vue"),
      },
    ],
  },
  {
    path: "/discord-upload-user",
    name: "discord-upload-user",
    component: () =>
      import("@/views/discord-upload-user/discord-upload-user.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
