<template>
  <div class="step2">
    <div class="container">
      <p class="header">Verify your account</p>

      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        class="form"
        :inline="true"
      >
        <el-form-item prop="firstName">
          <el-input
            v-model="ruleForm.firstName"
            auto-complete="off"
            placeholder="Enter your firstName"
          />
        </el-form-item>
        <el-form-item prop="lastName">
          <el-input
            v-model="ruleForm.lastName"
            auto-complete="off"
            placeholder="Enter your lastName"
          />
        </el-form-item>
        <el-form-item prop="nickname">
          <el-input
            v-model="ruleForm.nickname"
            auto-complete="off"
            placeholder="Enter your nickname"
          />
        </el-form-item>
        <el-form-item prop="sex">
          <el-select v-model="ruleForm.sex" placeholder="Choose your gender">
            <el-option label="Male" value="0"></el-option>
            <el-option label="Female" value="1"></el-option>
            <el-option label="Nonconforming" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="ruleForm.password"
            show-password
            type="password"
            auto-complete="off"
            placeholder="Enter your password"
          />
        </el-form-item>
        <el-form-item prop="password2">
          <el-input
            v-model="ruleForm.password2"
            show-password
            type="password"
            auto-complete="off"
            placeholder="Repeat your password"
          />
        </el-form-item>
        <el-form-item prop="Payment Pin">
          <el-input
            v-model="ruleForm.payCode"
            :maxlength="6"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            show-password
            type="password"
            auto-complete="off"
            placeholder="Enter your payment pin"
          />
          <p class="payment-pin-hint text-shadow">
            *Only 6 numbers accepted as your payment pin
          </p>
        </el-form-item>
        <el-form-item prop="Payment Pin">
          <el-input
            v-model="ruleForm.payCode2"
            :maxlength="6"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            show-password
            type="password"
            auto-complete="off"
            placeholder="Repeat your payment pin"
          />
        </el-form-item>
        <el-form-item prop="phone">
          <el-input
            v-model="ruleForm.phone"
            maxlength="13"
            auto-complete="off"
            placeholder="Enter your phone number"
            onkeyup="this.value=this.value.replace(/\D/g,'')"
            onafterpaste="this.value=this.value.replace(/\D/g,'')"
          />
        </el-form-item>
      </el-form>
      <div>
        <el-checkbox v-model="ruleForm.newsLetter" class="text-shadow">
          Sign up to our newsletters for updates and offers
        </el-checkbox>
        <el-checkbox v-model="ruleForm.policy" class="text-shadow">
          <span class="text-shadow">
            I agree to the UK SideQuest
            <a
              style="color: #fff833"
              href="/static/pdfjs/web/viewer.html?file=/static/policy/SideQuest-Privacy-Policy.pdf"
              target="_blank"
            >
              Privacy Policy
            </a>
            and
            <a
              href="/static/pdfjs/web/viewer.html?file=/static/policy/SideQuest-Terms-of-Service.pdf"
              target="_blank"
              style="color: #fff833"
            >
              Service Agreement
            </a>
          </span>
        </el-checkbox>
      </div>
      <el-form-item class="btn">
        <el-button type="primary" @click="handleBack">Back</el-button>
        <el-button type="primary" class="submit" @click="onSubmit"
          >Submit</el-button
        >
      </el-form-item>
    </div>
  </div>
</template>

<script>
import { reactive, ref, inject } from "vue";
import { useStore } from "vuex";

export default {
  name: "index-create",
  props: {
    step1Form: Object,
  },
  setup(props, { emit }) {
    const store = useStore();
    const rules = reactive({
      lastName: [
        {
          required: true,
          message: "Last name can not be empty",
          trigger: "blur",
        },
        {
          min: 2,
          max: 100,
          message: "Last name character length is between 2 and 100",
          trigger: "blur",
        },
      ],
      firstName: [
        {
          required: true,
          message: "first name must have value",
          trigger: "blur",
        },
        {
          min: 2,
          max: 100,
          message: "First name character length is between 2 and 100",
          trigger: "blur",
        },
      ],
      nickname: [
        {
          required: true,
          message: "Nickname cannot be empty",
          trigger: "blur",
        },
      ],
      sex: [
        { required: true, message: "Sex cannot be empty", trigger: "blur" },
      ],
      password: [
        {
          required: true,
          message: "Password cannot be empty",
          trigger: "blur",
        },
        {
          min: 6,
          max: 20,
          message: "Password character length is between 6 and 20",
          trigger: "blur",
        },
      ],
      password2: [
        {
          required: true,
          message: "Password cannot be empty",
          trigger: "blur",
        },
        {
          min: 6,
          max: 20,
          message: "Password character length is between 6 and 20",
          trigger: "blur",
        },
      ],
      payCode: [
        {
          required: true,
          message: "Payment pin cannot be empty",
          trigger: "blur",
        },
        {
          min: 6,
          max: 6,
          message: "Only 6 numbers accepted as your payment pin",
          trigger: "blur",
        },
      ],
      payCode2: [
        {
          required: true,
          message: "Payment pin cannot be empty",
          trigger: "blur",
        },
        {
          min: 6,
          max: 6,
          message: "Only 6 numbers accepted as your payment pin",
          trigger: "blur",
        },
      ],
      phone: [
        {
          required: true,
          pattern: /^[0-9]*$/,
          message: "Phone cannot be empty",
          trigger: "blur",
        },
      ],
    });
    const oldForm = inject("oldForm");
    let ruleForm = reactive({
      lastName: oldForm.value.lastName,
      firstName: oldForm.value.firstName,
      nickname: oldForm.value.nick,
      sex: "0",
      password: "",
      password2: "",
      payCode: "",
      payCode2: "",
      phone: oldForm.value.phone,
      newsLetter: "",
      policy: "",
    });
    const ruleFormRef = ref(null);
    const handleClose = () => {
      emit("handleClose");
    };
    const handleClick = (v) => {
      emit("handleClick", v);
    };

    const onSubmit = async () => {
      if (!ruleForm.newsLetter) {
        store.dispatch("http/setHttpState", {
          msg: "News letter cannot be empty",
          dialogVisible: true,
        });
        return;
      }
      if (!ruleForm.policy) {
        store.dispatch("http/setHttpState", {
          msg: "policy cannot be empty",
          dialogVisible: true,
        });
        return;
      }
      // 检验密码是否一致
      if (ruleForm.password !== ruleForm.password2) {
        store.dispatch("http/setHttpState", {
          msg: "password need be the same",
          dialogVisible: true,
        });
        return;
      }
      // 检验支付密码是否一致
      if (ruleForm.payCode !== ruleForm.payCode2) {
        store.dispatch("http/setHttpState", {
          msg: "pay code need be the same",
          dialogVisible: true,
        });
        return;
      }
      // 验证密码跟支付是否一致
      if (ruleForm.payCode == ruleForm.password) {
        store.dispatch("http/setHttpState", {
          msg: "payment pin cannot same to the password",
          dialogVisible: true,
        });
        return;
      }
      await ruleFormRef.value.validate((valid) => {
        if (valid) {
          emit("handleSubmit", ruleForm);
        }
      });
    };
    const handleBack = () => {
      emit("handleBack");
    };
    return {
      ruleForm,
      rules,
      ruleFormRef,
      handleClose,
      onSubmit,
      handleClick,
      handleBack,
    };
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/base" as *;

.step2 {
  @include media("<phone") {
    height: 100%;
    overflow-y: scroll;
  }

  .container {
    width: 100%;
    max-width: 760px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    padding: 0 24px;
    color: #ffffff;

    @include media("<phone") {
      position: relative;
      top: 120px;
      transform: unset;
      left: unset;
      margin: 0 auto;
      padding: 0 42px 48px;
    }

    .header {
      @include text-4xl;
      @include font-bold;
      padding-bottom: 30px;
    }

    :deep(.form) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;

      .el-form-item {
        margin-right: 0;

        @include media("<phone") {
          width: 100%;
        }
      }

      .el-form-item__content {
        display: block;

        @include media("<phone") {
          width: 100%;
        }
      }

      .el-form-item__error {
        @include text-base;
      }

      .el-button {
        @include button-base;
        @include text-xl;
        width: 216px;
        height: 55px;
      }

      .el-input {
        border: none;
        height: 70px;
        /* width: 370px; */

        @include media("<phone") {
          width: 100%;
        }

        .el-input__wrapper {
          height: 100%;
          background: #272c38;
          border-radius: 12px;
          border: none;

          .el-input__inner {
            @include text-lg;
            width: 100%;
            height: 100%;
            color: #ffffff;
            background-color: #272c38;
          }
        }

        .el-input-group__append {
          margin-left: 20px;
          border-radius: 8px;
          padding: 0;

          .verify {
            padding: 0 36px;
            cursor: pointer;
          }
        }
      }

      .el-select {
        height: 70px;

        .el-select__wrapper {
          @include text-lg;
          --el-text-color-regular: white;
          height: 100%;
        }
      }

      .payment-pin-hin {
        padding-top: 4px;
        color: #efefef;
      }
    }

    .text-shadow {
      text-shadow: 0 1px 3px black;
    }

    :deep(.el-checkbox) {
      .el-checkbox__label {
        color: #ffffff;
      }
    }

    :deep(.btn) {
      text-align: center;

      .el-button {
        @include button-base;
        @include text-xl;
        width: 216px;
        height: 55px;
        margin: 0;

        &.submit {
          color: white;
        }

        @include media("<phone") {
          width: 100%;
        }
      }

      .el-form-item__content {
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
        gap: 16px;
        margin-top: 24px;

        @include media("<phone") {
          flex-direction: column-reverse;
        }
      }
    }
  }
}
</style>
