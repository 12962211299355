import * as types from "./mutation_types"

export default {
    [types.NEWS_LIST] (state:any,data=[]) {
        state.newsList = data
    },
    [types.NEWS_DETAIL] (state:any,data={}) {
        state.newsDetail = data
    },
}
