<template>
  <div class="step">
    <div class="container">
      <p class="header">Verify your account</p>

      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        class="form"
        label-width="160PX"
      >
        <el-form-item prop="userName" label="SideQuest ID：">
          <el-input
            v-model="ruleForm.userName"
            auto-complete="off"
            placeholder=""
          />
        </el-form-item>

        <el-radio-group v-model="ruleForm.name" class="ml-4">
          <div>
            <el-radio label="email" size="large">
              <span>Email：</span>
              <el-input
                :disabled="ruleForm.name !== 'email'"
                v-model="ruleForm.email"
                auto-complete="off"
                placeholder="Input your email"
              />
            </el-radio>
          </div>
          <div>
            <el-radio label="phone" size="large">
              <span>Phone：</span>
              <el-input
                :disabled="ruleForm.name !== 'phone'"
                v-model="ruleForm.phone"
                auto-complete="off"
                placeholder="Input your phone number"
              />
            </el-radio>
          </div>
          <div>
            <el-radio label="certifiNo" size="large">
              <span>CertifiNo：</span>
              <el-input
                :disabled="ruleForm.name !== 'certifiNo'"
                v-model="ruleForm.certifiNo"
                auto-complete="off"
                placeholder="ID,Passports,Stdent accountss when you registier"
              />
            </el-radio>
          </div>
        </el-radio-group>

        <el-form-item class="btn">
          <el-button type="primary" @click="handleBack">Back</el-button>
          <el-button type="primary" class="next" @click="handleNext"
            >Next</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "index-old",
  setup(props, { emit }) {
    const store = useStore();
    const rules = reactive({
      userName: [{ required: true, message: "userName is required" }],
    });
    const ruleForm = reactive({
      userName: "",
      name: "email",
      email: "",
      phone: "",
      certifiNo: "",
    });
    const ruleFormRef = ref(null);

    /**
     * 下一步
     */
    const handleNext = async () => {
      let pattern =
        /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/.test(
          ruleForm.email,
        );
      if (ruleForm.name === "email" && !pattern) {
        store.dispatch("http/setHttpState", {
          msg: "email must have a valid value",
          dialogVisible: true,
        });
        ruleForm.email = "";
        return;
      }
      if (ruleForm.name === "phone" && ruleForm.phone === "") {
        store.dispatch("http/setHttpState", {
          msg: "Please input your phone number",
          dialogVisible: true,
        });
        ruleForm.email = "";
        return;
      }
      if (ruleForm.name === "certifiNo" && ruleForm.certifiNo === "") {
        store.dispatch("http/setHttpState", {
          msg: "Please input your CertifiNo",
          dialogVisible: true,
        });
        ruleForm.email = "";
        return;
      }
      await ruleFormRef.value.validate((valid) => {
        if (valid) {
          let data = {
            username: ruleForm.userName,
            password: "123456",
          };
          store.dispatch("user/userVerify", data).then((res) => {
            if (res.Secondary === 1 && res.validate === 1) {
              sessionStorage.setItem("Authorization", res.token);
              let verifyData = {
                name: ruleForm.name,
                value:
                  ruleForm.name === "email"
                    ? ruleForm.email
                    : ruleForm.name === "phone"
                      ? ruleForm.phone
                      : ruleForm.certifiNo,
              };
              verifyFun(verifyData);
            } else {
              store.dispatch("http/setHttpState", {
                msg: "You account validated already!",
                dialogVisible: true,
              });
            }
          });
        }
      });
    };

    const verifyFun = (data) => {
      store.dispatch("user/secondary", data).then((res) => {
        if (res) {
          store.dispatch("http/setHttpState", {
            msg: "Verification passed, please provide supplementary information.",
            dialogVisible: true,
            state: true,
          });
          emit("handleNext", res);
        }
      });
    };
    const handleBack = () => {
      emit("handleBack");
    };
    return {
      ruleForm,
      rules,
      ruleFormRef,
      handleNext,
      handleBack,
    };
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/base" as *;

.step {
  @include media("<phone") {
    height: 100%;
    overflow-y: scroll;
  }

  .container {
    width: 100%;
    max-width: 560px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    color: #ffffff;
    padding: 0 24px;

    @include media("<phone") {
      position: relative;
      top: 120px;
      transform: unset;
      left: unset;
      margin: 0 auto;
      padding: 0 42px 48px;
    }

    .header {
      @include text-4xl;
      @include font-bold;
      padding-bottom: 30px;

      @include media("<phone") {
        padding-bottom: 0;
      }
    }

    :deep(.form) {
      .el-form-item {
        margin-bottom: 26px;
      }

      .el-form-item__error {
        @include text-base;
      }

      .el-form-item__label {
        @include text-shadow;
      }

      .el-button {
        @include button-base;
        @include text-xl;
        width: 216px;
        height: 55px;

        &.next {
          color: white;

          @include media("<phone") {
            margin: 0;
          }
        }
      }

      .el-radio-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
        margin: 0;

        > div {
          width: 100%;
          height: fit-content;
        }

        .el-radio {
          width: 100%;
          height: 55px;
          line-height: 55px;
          margin-bottom: 26px;

          @include media("<phone") {
            height: fit-content;
            display: flex;
            place-items: start;

            .el-radio__input {
              margin-top: 7px;
            }
          }
        }

        .el-radio__label {
          display: grid;
          grid-template-columns: 138px 1fr;
          place-items: center;
          width: 100%;

          @include media("<phone") {
            grid-template-columns: 1fr;
            place-items: start;
            gap: 8px;
          }

          span {
            @include text-shadow;
            @include text-lg;
            display: inline-block;
            width: 138px;
            color: #ffffff;
          }
        }
      }

      .el-input {
        width: 100%;
        height: 70px;
        border: none;

        .el-input__wrapper {
          height: 100%;
          background: #272c38;
          border-radius: 12px;
          border: none;

          .el-input__inner {
            @include text-lg;
            width: 100%;
            height: 100%;
            color: #ffffff;
            background-color: #272c38;
          }
        }

        .el-input-group__append {
          margin-left: 20px;
          border-radius: 8px;
          padding: 0;

          .verify {
            padding: 0 36px;
            cursor: pointer;
          }
        }
      }

      .el-form-item {
        margin-top: 20px;

        @include media("<phone") {
          flex-direction: column;
          gap: 16px;

          .el-input__wrapper {
            margin-left: 20px;
          }
        }

        .el-form-item__label {
          @include text-lg;
          line-height: 50px;
          color: #ffffff;
          text-align: left;
          justify-content: flex-start;
        }

        .el-form-item__label::before {
          display: none;
        }
      }

      .text-shadow {
        text-shadow: 0 1px 3px black;
      }

      .btn {
        background: none;
        color: #ffffff;

        .el-form-item__content {
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;

          @include media("<phone") {
            margin-left: 20px !important;
            flex-direction: column-reverse;
            gap: 16px;

            .el-button {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
