import * as types from "./mutation_types"

export default {
    [types.SHPW_DATA] (state:any,data:{token:string}) {
        state.shpwData = data
        if(data&&data.token){
            sessionStorage.setItem('Authorization',data.token)
        }else {
            sessionStorage.clear()
        }
    },
    [types.PRE_ORDER_DATA] (state:any,data:object) {
        state.preOrderData = data
    },
}
