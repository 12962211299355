import * as types from "./mutation_types"

export default {
    [types.HTTP_DATA] (state:any,data:any) {
        state.httpData = data
    },
    [types.LOGIN_STATE] (state:any,data:any) {
        state.loginState = data
    },
    [types.REACT_DATA] (state:any) {
        state.httpData = {
            msg:'',
            state:false,
            dialogVisible:false,
        }
    },
}
