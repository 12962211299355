<template>
  <div class="step2">
    <div class="container">
      <p class="header">CREATE ACCOUNT</p>

      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        class="form"
        :inline="true"
      >
        <!--      <el-form-item prop="firstName">-->
        <!--        <el-input v-model="ruleForm.firstName" auto-complete="off" placeholder="Enter your firstName"/>-->
        <!--      </el-form-item>-->
        <!--      <el-form-item prop="lastName">-->
        <!--        <el-input v-model="ruleForm.lastName" auto-complete="off" placeholder="Enter your lastName"/>-->
        <!--      </el-form-item>-->
        <el-form-item prop="nickname">
          <el-input
            v-model="ruleForm.nickname"
            auto-complete="off"
            placeholder="Enter your nickname"
          />
        </el-form-item>
        <el-form-item prop="sex">
          <el-select v-model="ruleForm.sex" placeholder="Choose your gender">
            <el-option label="Male" value="0"></el-option>
            <el-option label="Female" value="1"></el-option>
            <el-option label="Nonconforming" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="ruleForm.password"
            show-password
            type="password"
            auto-complete="off"
            placeholder="Enter your password"
          />
        </el-form-item>
        <el-form-item prop="password2">
          <el-input
            v-model="ruleForm.password2"
            show-password
            type="password"
            auto-complete="off"
            placeholder="Repeat your password"
          />
        </el-form-item>
        <el-form-item prop="Payment Pin">
          <el-input
            v-model="ruleForm.payCode"
            :maxlength="6"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            show-password
            type="password"
            auto-complete="off"
            placeholder="Enter your payment pin"
          />
          <p class="payment-pin-hint">
            *Only 6 numbers accepted as your payment pin
          </p>
        </el-form-item>
        <el-form-item prop="Payment Pin">
          <el-input
            v-model="ruleForm.payCode2"
            :maxlength="6"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            show-password
            type="password"
            auto-complete="off"
            placeholder="Repeat your payment pin"
          />
        </el-form-item>
        <el-form-item prop="phone">
          <el-input
            v-model="ruleForm.phone"
            maxlength="13"
            auto-complete="off"
            placeholder="Enter your phone number"
            onkeyup="this.value=this.value.replace(/\D/g,'')"
            onafterpaste="this.value=this.value.replace(/\D/g,'')"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="ruleForm.invite"
            maxlength="13"
            auto-complete="off"
            placeholder="Invite code(Optional)"
          />
        </el-form-item>
      </el-form>
      <div>
        <el-checkbox v-model="ruleForm.newsLetter">
          Sign up to our newsletters for updates and offers
        </el-checkbox>
        <el-checkbox v-model="ruleForm.policy">
          I agree to the UK SideQuest
          <a
            style="color: #fff833"
            href="/static/pdfjs/web/viewer.html?file=/static/policy/SideQuest-Privacy-Policy.pdf"
            target="_blank"
          >
            Privacy Policy
          </a>
          and
          <a
            href="/static/pdfjs/web/viewer.html?file=/static/policy/SideQuest-Terms-of-Service.pdf"
            target="_blank"
            style="color: #fff833"
          >
            Service Agreement
          </a>
        </el-checkbox>
      </div>
      <div class="btn">
        <el-button type="primary" class="button-back" @click="handleBack"
          >Back</el-button
        >
        <el-button :disabled="btnState" type="primary" @click="onSubmit"
          >Submit</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { inject, reactive, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "index-create",
  setup(props, { emit }) {
    const store = useStore();
    const rules = reactive({
      lastName: [
        {
          required: true,
          message: "last name can not be empty",
          trigger: "blur",
        },
        {
          min: 2,
          max: 100,
          message: "last name character length is between 2 and 100",
          trigger: "blur",
        },
      ],
      firstName: [
        {
          required: true,
          message: "first name must have value",
          trigger: "blur",
        },
        {
          min: 2,
          max: 100,
          message: "first name character length is between 2 and 100",
          trigger: "blur",
        },
      ],
      nickname: [
        {
          required: true,
          message: "nick name cannot be empty",
          trigger: "blur",
        },
      ],
      sex: [
        { required: true, message: "sex cannot be empty", trigger: "blur" },
      ],
      password: [
        {
          required: true,
          message: "password cannot be empty",
          trigger: "blur",
        },
        {
          min: 6,
          max: 20,
          message: "password character length is between 6 and 20",
          trigger: "blur",
        },
      ],
      password2: [
        {
          required: true,
          message: "password cannot be empty",
          trigger: "blur",
        },
        {
          min: 6,
          max: 20,
          message: "password character length is between 6 and 20",
          trigger: "blur",
        },
      ],
      payCode: [
        {
          required: true,
          message: "payment pin cannot be empty",
          trigger: "blur",
        },
        {
          min: 6,
          max: 6,
          message: "Only 6 numbers accepted as your payment pin",
          trigger: "blur",
        },
      ],
      payCode2: [
        {
          required: true,
          message: "payment pin cannot be empty",
          trigger: "blur",
        },
        {
          min: 6,
          max: 6,
          message: "Only 6 numbers accepted as your payment pin",
          trigger: "blur",
        },
      ],
      phone: [
        {
          required: true,
          pattern: /^[0-9]*$/,
          message: "phone cannot be empty",
          trigger: "blur",
        },
      ],
    });
    let ruleForm = reactive({
      lastName: "",
      firstName: "",
      nickname: "",
      sex: "0",
      password: "",
      password2: "",
      payCode: "",
      payCode2: "",
      phone: "",
      invite: "",
      newsLetter: true,
      policy: true,
    });
    const ruleFormRef = ref(null);
    const btnState = inject("btnState");
    const handleClose = () => {
      emit("handleClose");
    };
    const handleClick = (v) => {
      emit("handleClick", v);
    };

    const onSubmit = async () => {
      // 訂閱不需要強制
      // if (!ruleForm.newsLetter) {
      //   store.dispatch("http/setHttpState", {
      //     msg: "News letter cannot be empty",
      //     dialogVisible: true,
      //   });
      //   return;
      // }
      if (!ruleForm.policy) {
        store.dispatch("http/setHttpState", {
          msg: "policy cannot be empty",
          dialogVisible: true,
        });
        return;
      }
      // 检验密码是否一致
      if (ruleForm.password !== ruleForm.password2) {
        store.dispatch("http/setHttpState", {
          msg: "password need be the same",
          dialogVisible: true,
        });
        return;
      }
      // 检验支付密码是否一致
      if (ruleForm.payCode !== ruleForm.payCode2) {
        store.dispatch("http/setHttpState", {
          msg: "pay code need be the same",
          dialogVisible: true,
        });
        return;
      }
      // 验证密码跟支付是否一致
      if (ruleForm.payCode == ruleForm.password) {
        store.dispatch("http/setHttpState", {
          msg: "payment pin cannot same to the password",
          dialogVisible: true,
        });
        return;
      }
      await ruleFormRef.value.validate((valid) => {
        if (valid) {
          ruleForm.firstName = ruleForm.nickname;
          ruleForm.lastName = ruleForm.nickname;
          emit("handleSubmit", ruleForm);
        }
      });
    };

    const handleBack = () => {
      emit("handleBack");
    };
    return {
      ruleForm,
      rules,
      btnState,
      ruleFormRef,
      handleClose,
      onSubmit,
      handleClick,
      handleBack,
    };
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/base" as *;

.step2 {
  @include media("<phone") {
    width: 100dvw;
    height: 100dvh;
    overflow-y: scroll;
  }

  .container {
    width: 100%;
    max-width: 760px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    color: #ffffff;

    @include media("<wide") {
      padding: 0 24px;
    }

    @include media("<phone") {
      position: relative;
      top: 120px;
      transform: unset;
      left: unset;
      margin: 0 auto;
      padding: 0 42px 48px;
    }

    .header {
      @include text-4xl;
      @include font-bold;
      padding-bottom: 30px;
    }

    :deep(.form) {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;

      @include media("<phone") {
        grid-template-columns: 1fr;
      }

      .el-form-item {
        margin-right: 0;
      }

      .el-form-item__content {
        padding-top: 10px;
        display: block;
      }

      .el-form-item__error {
        @include text-base;
      }

      .el-button {
        @include button-base;
        @include text-xl;
        width: 216px;
        height: 55px;
        margin-top: 44px;
      }

      .el-input {
        border: none;
        height: 70px;
        width: 100%;
        max-width: 370px;

        @include media("<phone") {
          max-width: unset;
        }

        .el-input__wrapper {
          width: 100%;
          height: 100%;
          background: #272c38;
          border-radius: 12px;
          border: none;

          .el-input__inner {
            @include text-lg;
            width: 100%;
            height: 100%;
            line-height: 50px;
            color: #ffffff;
            background-color: #272c38;
          }
        }

        .el-input-group__append {
          margin-left: 20px;
          border-radius: 8px;
          padding: 0;

          .verify {
            padding: 0 36px;
            cursor: pointer;
          }
        }
      }

      .payment-pin-hint {
        @include text-shadow;
        padding-top: 4px;
        color: #efefef;
      }

      .el-select {
        height: 70px;
        width: 100%;
        max-width: 370px;

        @include media("<phone") {
          max-width: unset;
        }

        .el-select__wrapper {
          height: 100%;
          --el-text-color-regular: white;

          span {
            @include text-lg;
          }
        }
      }
    }

    :deep(.el-checkbox) {
      .el-checkbox__label {
        @include text-shadow;
        color: #ffffff;
      }
    }

    :deep(.btn) {
      display: flex;
      gap: 16px;
      text-align: center;

      .el-button {
        @include button-base;
        @include text-xl;
        width: 216px;
        height: 55px;
        margin-top: 42px;

        @include media("<phone") {
          width: 100%;
        }

        &.button-back {
          background: #bbbbbb;
        }
      }

      .el-form-item__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
      }
    }
  }
}
</style>
