<template>
  <div class="http">
    <el-dialog
      v-model="data.dialogVisible"
      :before-close="handleClose"
      center
      class="http-el-dialog"
      :close-on-click-modal="false"
    >
      <div class="content">
        <div>
          <img
            v-if="!data.state"
            src="@/assets/web/retrieve_error.png"
            alt=""
          />
          <img v-else src="@/assets/web/retrieve_success.png" alt="" />
        </div>
        <div style="word-break: break-word" v-html="data.msg"></div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="handleClose">OK</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "http-web",
  setup() {
    const store = useStore();
    const data = computed(() => store.state.http.httpData);
    const handleClose = () => {
      store.dispatch("http/resetHttpState");
    };
    return {
      handleClose,
      data,
    };
  },
};
</script>

<style lang="scss">
@use "@/styles/base" as *;

.el-dialog {
  background: #1c1f24;
  border-radius: 14px;
  width: 60vw;
  padding: 24px;
  min-width: fit-content;
  max-width: 640px;

  @include media("<tablet") {
    width: 70vw;
  }

  @include media("<phone") {
    width: 90vw;
  }

  .content {
    @include text-xl;
    text-align: center;
    color: #ffffff;

    img {
      width: 80px;
      padding-bottom: 20px;
      margin: 0 auto;
    }
  }

  .dialog-footer {
    display: flex;
    place-content: center;

    .el-button {
      @include button-base;
      width: 120px;
    }
  }
}
</style>
