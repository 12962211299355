<template>
  <div class="step1">
    <p class="header">CREATE ACCOUNT</p>

    <p class="sub-header" v-if="differYear >= 13 && differYear < 16">
      Players under the age of 16 must provide an emergency contact in order to
      use our services and sign up.
    </p>
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" class="form">
      <el-form-item prop="birth">
        <el-date-picker
          v-model="ruleForm.birth"
          type="date"
          format="DD/MM/YYYY"
          placeholder="Enter your Birthday"
          @change="timeChange"
        />
      </el-form-item>
      <el-form-item prop="email">
        <el-input
          v-model="ruleForm.email"
          autocomplete="new-password"
          placeholder="Enter your email"
        >
          <template #append v-if="differYear >= 16 || differYear === 0">
            <div class="verify" @click="handleVerify">
              {{ num ? num : "Verify" }}
            </div>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="guardian" v-if="differYear >= 13 && differYear < 16">
        <el-input
          v-model="ruleForm.guardian"
          autocomplete="new-password"
          placeholder="Enter your Guardian email"
        >
          <template #append>
            <div class="verify" @click="handleVerify">
              {{ num ? num : "Verify" }}
            </div>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="verifyCode">
        <el-input
          v-model="ruleForm.verifyCode"
          auto-complete="off"
          placeholder="Enter your Verification code"
        />
      </el-form-item>

      <el-form-item class="btn">
        <el-button type="primary" class="button-back" @click="handleBack"
          >Back</el-button
        >
        <el-button type="primary" class="button-next" @click="handleNext"
          >Next</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "index-create",
  setup(props, { emit }) {
    const store = useStore();
    const rules = reactive({
      email: [{ required: true, message: "email is required" }],
      guardian: [{ required: true, message: "guardian is required" }],
      verifyCode: [
        { required: true, message: "verification code is required" },
      ],
      birth: [{ required: true, message: "birthday is required" }],
    });
    const ruleForm = reactive({
      email: "",
      guardian: "",
      verifyCode: "",
      birth: "",
      uid: "",
    });
    const ruleFormRef = ref(null);
    const differYear = ref(0);

    const timeChange = (v) => {
      let birthdays = new Date(v);
      let d = new Date();
      differYear.value =
        d.getFullYear() -
        birthdays.getFullYear() -
        (d.getMonth() < birthdays.getMonth() ||
        (d.getMonth() == birthdays.getMonth() &&
          d.getDate() < birthdays.getDate())
          ? 1
          : 0);
      // console.log(this.differYear)
      console.log(differYear.value);
      if (differYear.value < 13) {
        store.dispatch("http/setHttpState", {
          msg: "Children under the age of 13 are not eligible to use our Service.",
          dialogVisible: true,
        });
        ruleForm.birth = "";
      }
    };

    /**
     * 获取邮箱验证码
     * @type {Ref<any>}
     */
    const num = ref();
    const handleVerify = () => {
      if (num.value) {
        return;
      }
      let pattern =
        /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/.test(
          ruleForm.email,
        );
      if (!pattern) {
        store.dispatch("http/setHttpState", {
          msg: "email must have a valid value",
          dialogVisible: true,
        });
        ruleForm.email = "";
        return;
      }
      ruleFormRef.value.validateField("birth", (errorMessage) => {
        if (errorMessage) {
          store
            .dispatch("user/sendEmail", {
              email:
                differYear.value >= 16 ? ruleForm.email : ruleForm.guardian,
            })
            .then((res) => {
              if (res) {
                console.log(res);
                ruleForm.uid = res.uid;
                num.value = 60;
                let time = setInterval(function () {
                  num.value--;
                  if (num.value === 0) {
                    clearInterval(time);
                    num.value = undefined;
                  }
                }, 1000);
              }
            });
        }
      });
    };

    /**
     * 下一步
     */
    const handleNext = async () => {
      let pattern =
        /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/.test(
          ruleForm.email,
        );
      if (!pattern) {
        store.dispatch("http/setHttpState", {
          msg: "Email must have a valid value",
          dialogVisible: true,
        });
        ruleForm.email = "";
        return;
      }
      await ruleFormRef.value.validate((valid) => {
        if (valid) {
          let data = {
            code: ruleForm.verifyCode,
            uid: ruleForm.uid,
          };
          store.dispatch("user/verifyEmail", data).then((res) => {
            if (res) {
              emit("handleNext", ruleForm);
            }
          });
        }
      });
    };

    const handleBack = () => {
      emit("handleBack");
    };
    return {
      ruleForm,
      rules,
      ruleFormRef,
      num,
      differYear,
      handleNext,
      handleVerify,
      timeChange,
      handleBack,
    };
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/base" as *;

.step1 {
  max-width: 460px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  color: #ffffff;
  padding: 0 24px;

  @include media("<tablet") {
    width: 100%;
    padding: 24px;
  }

  .header {
    @include text-4xl;
    @include font-bold;
    padding-bottom: 30px;
  }

  .sub-header {
    @include text-xl;
    @include font-bold;
    padding-bottom: 10px;
    color: red;
  }

  :deep(.form) {
    display: flex;
    flex-direction: column;
    gap: 38px;

    .el-form-item {
      margin-bottom: unset;
    }

    .el-form-item__error {
      @include text-base;
    }

    .el-button {
      @include button-base;
      @include text-xl;
      width: 216px;
      height: 55px;
    }

    .el-input {
      border: none;
      height: 70px;
      /* width: 460px; */
      width: 100%;
      display: flex;
      gap: 16px;

      @include media("<tablet") {
        width: 100%;
      }

      .el-input__wrapper {
        /* width: 100%; */
        /* height: 100%; */
        background: #272c38;
        border-radius: 12px;
        border: none;

        .el-input__inner {
          @include text-lg;
          width: 100%;
          height: 100%;
          color: #ffffff;
          background-color: #272c38;
        }
      }

      .el-input-group__append {
        padding: 0;
        border: 0;
        box-shadow: unset;
        background-color: transparent;

        .verify {
          @include button-base;
          width: 72px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 36px;
        }
      }
    }

    .btn {
      background: none;

      .button-back {
        background: #bbbbbb;
      }
      .button-next {
        color: white;
      }

      .el-form-item__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
      }
    }
  }
}
</style>
