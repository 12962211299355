<template>
  <div class="booking-bar" v-loading.fullscreen.lock="loading">
    <div id="booking" class="md:bottom-0 md:fixed z-10 w-full" ref="root">
      <div
        class="flex items-center flex-col md:flex-row md:h-[12vh] gap-4 bg-slate-800 md:px-4 md:border-t-2 md:border-[#FFB20E]"
      >
        <span class="hidden md:block md:w-[12vw] text-white font-black text-xl"
          >Want to come in?</span
        >
        <div class="header">
          <p class="booking-title">Booking</p>
          <p class="cancel" @click="$emit('onClose')">Cancel</p>
        </div>
        <el-form
          :inline="true"
          ref="ruleFormRef"
          :model="bookForm"
          :rules="rules"
        >
          <div class="form-data">
            <el-form-item prop="storeId">
              <el-select
                v-model="bookForm.storeId"
                placeholder="Where?"
                @change="storeChange"
                class="custom-select"
              >
                <el-option
                  v-for="(item, index) in storeList"
                  :label="item.name"
                  :key="index"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="areaId">
              <el-select
                v-model="bookForm.areaId"
                placeholder="Room.."
                @change="areaChange"
              >
                <el-option
                  v-for="(item, index) in areaList"
                  :label="item.areaName"
                  :key="index"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="date">
              <el-date-picker
                v-model="bookForm.date"
                type="date"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                placeholder="When?"
                :disabled-date="disabledTime"
                @change="timeChange"
              />
            </el-form-item>
            <el-form-item prop="time">
              <el-time-select
                v-model="bookForm.time"
                placeholder="Time"
                :start="storeStartTime"
                step="00:30"
                :end="storeEndTime"
              />
            </el-form-item>
            <el-form-item prop="period">
              <el-select
                v-model="bookForm.period"
                placeholder="How long.."
                class="booking-width"
              >
                <el-option
                  v-for="item of 5"
                  :label="item"
                  :key="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="computerNeed">
              <el-select
                v-model="bookForm.computerNeed"
                placeholder="Players.."
                class="booking-width"
              >
                <el-option
                  v-for="item of computers"
                  :label="item"
                  :key="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </div>
          <div class="form-buttons">
            <el-form-item>
              <el-button type="primary" @click="onSubmit">BOOK NOW</el-button>
            </el-form-item>
            <el-form-item>
              <a
                class="el-button text-wrap w-[5vw] rounded-md font-black h-[5vh] leading-5"
                href="https://forms.gle/4rQZpvu7QMZKscp67"
                target="_blank"
                >PARTY BOOKING</a
              >
            </el-form-item>
          </div>
        </el-form>
        <div class="info md:hidden">
          <p>
            *For any bootcamps/party bookings please contact
            support@sidequestmeta.com. If you wish to organise a gaming event
            with us then contact event@sidequestmeta.com
          </p>
          <p>
            *We require at least 4 people to attend bookings for Squad and
            Battle Rooms, and 2 people for Duo Rooms. If less people arrive for
            the booking then the deposit will not be refunded.
          </p>
          <p>
            *If you are late by more than 30 minutes to your booked time then
            your reservation will be cancelled and deposit won’t be refunded.
          </p>
        </div>
      </div>
    </div>

    <el-dialog
      v-model="dialogVisible"
      width="410PX"
      :before-close="handleClose"
      append-to-body
      center
    >
      <div class="content">
        <div
          style="
            display: flex;
            justify-content: flex-start;
            align-items: center;
          "
        >
          <p style="margin-right: 20px; width: 120px">Phone:</p>
          <el-input
            type="number"
            v-model="phone"
            :placeholder="userData.phone"
          />
        </div>
        <div style="word-break: break-word; margin-top: 20px; text-align: left">
          The deposit of your booking is £{{ bookingPrice }}
        </div>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="handleClose">Close</el-button>
          <el-button type="primary" @click="handleAffirm">OK</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, toRaw } from "vue";
import { useStore } from "vuex";
import upArrowSvg from "@/components/web/home/upArrowSvg";
import { useSpring, useMotionProperties } from "@vueuse/motion";

export default {
  name: "booking-bar",
  emits: ["onClose"],
  components: {
    upArrowSvg,
  },
  setup() {
    const store = useStore();
    const bookForm = reactive({
      storeId: "",
      areaId: "",
      date: "",
      period: "",
      time: "",
      computerNeed: "",
    });
    const rules = reactive({
      storeId: [{ required: true, message: "Store is required" }],
      areaId: [{ required: true, message: "Area is required" }],
      date: [{ required: true, message: "Date is required" }],
      time: [{ required: true, message: "Time is required" }],
      period: [{ required: true, message: "Period is required" }],
      computerNeed: [{ required: true, message: "Computer Need is required" }],
    });
    const dialogVisible = ref(false);
    const isBookingShow = ref(false);
    const root = ref();
    const openBar = ref();
    const closeBar = ref();

    let areaList = reactive([]);
    let computers = ref(0);
    let description = ref([]);
    let phone = ref("");
    let storeStartTime = ref("");
    let storeEndTime = ref("");
    let bookingPrice = ref(0);
    const loading = ref(false);

    const storeAreaList = computed(() => store.state.home.storeAreaList);
    const storeList = computed(() => store.state.home.storeList);
    const userData = computed(() => store.state.user.userData);

    const storeData = ref(null);

    onMounted(() => {
      store.dispatch("home/getStoreAreaList");
      store.dispatch("home/getStoreList");
      window.addEventListener("scroll", scrollTop, true);

      const { motionProperties } = useMotionProperties(root.value, {
        opacity: 1,
        y: 0,
      });

      const { set } = useSpring(motionProperties, {
        duration: 1000,
        bounce: 0.0,
      });

      openBar.value = () => set({ opacity: 1, y: 0 });
      closeBar.value = () => set({ opacity: 0, y: 300 });
    });

    const scrollTop = () => {
      const scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      const isShow = scroll > 200;
      if (!isBookingShow.value && isShow) closeBar.value();
      if (isBookingShow.value && !isShow) openBar.value();

      isBookingShow.value = isShow;
    };

    /**
     * 门店切换
     * @param v
     */
    const storeChange = (v) => {
      areaList.length = 0;
      bookForm.areaId = "";
      let data = toRaw(storeAreaList.value);
      let newData = data.filter((item) => {
        if (item.storeId == v) return item;
      });
      areaList.push(...newData);

      let newStoreList = toRaw(storeList.value);
      storeData.value = newStoreList.filter((item) => {
        if (item.id == v) return item;
      });
    };

    /**
     * 区域切换
     * @param v
     */
    const areaChange = (v) => {
      let data = areaList.filter((item) => {
        return item.id === v;
      });
      let areaData = toRaw(data[0]);
      computers.value = areaData.computers;
      bookingPrice.value = areaData.bookingPrice;
      description.value = JSON.parse(areaData.description);
    };

    const timeChange = (date) => {
      description.value.forEach((item) => {
        if (item.week == new Date(date).getDay()) {
          const start = item.startTime.split(":")[0];
          const end = item.endTime.split(":")[0];
          storeStartTime.value = item.startTime;
          if (Number(start) > Number(end)) {
            storeEndTime.value = "24:00";
          } else {
            storeEndTime.value = item.endTime;
          }
        }
      });
    };

    /**
     * 禁止选择时间
     * @param time
     * @returns {boolean}
     */
    const disabledTime = (time) => {
      const tomorrow = new Date();
      tomorrow.setTime(tomorrow.getTime() + 3600 * 1000 * 24);
      return time.getTime() < Date.now();
    };

    const ruleFormRef = ref(null);
    /**
     * 预约门店
     */
    const onSubmit = () => {
      if (!userData.value) {
        store.dispatch("http/setLoginState", true);
        return;
      }
      ruleFormRef.value.validate((valid) => {
        if (valid) {
          let beginTime = bookForm.date + " " + bookForm.time + ":00";
          let arr = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          let week = new Date(beginTime).getDay();
          let openTime = JSON.parse(storeData.value[0].openTime);
          let str = arr[week];
          let timeArr = openTime[str].split(" - ");
          let startTime = timeArr[0];
          let endTime = timeArr[1];

          // if (!checkAuditTime(startTime, endTime, beginTime)) {
          //   store.dispatch("http/setHttpState", {
          //     msg: `Out of business hours. The Store opening hours: ${startTime} - ${endTime}`,
          //     dialogVisible: true,
          //     state: false,
          //   });
          //   return;
          // }
          dialogVisible.value = true;
        }
      });
    };

    /**
     * 关闭确认弹窗
     */
    const handleClose = () => {
      dialogVisible.value = false;
    };

    /**
     * 提交预约数据
     */
    const handleAffirm = () => {
      handleClose();
      bookForm.beginTime = bookForm.date + " " + bookForm.time + ":00";
      bookForm.memberId = userData.value ? userData.value.id : "";
      bookForm.phone = phone.value ? phone.value : userData.value.phone;
      store.dispatch("home/addBooking", bookForm).then((res) => {
        if (res) {
          ruleFormRef.value.resetFields();
          store.dispatch("http/setHttpState", {
            msg: "Booking successfully!",
            dialogVisible: true,
            state: true,
          });
        }
      });
    };

    const checkAuditTime = (startTime, endTime, time) => {
      // 获取当前时间
      const date = new Date(time);
      // 获取当前时间的年月日
      const startStr = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()} `;
      let endStr = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()} `;
      // 如果开始时间小于结束时间，则结束时间加一天
      if (endTime < startTime) {
        const tomorrow = new Date(date.getTime() + 24 * 60 * 60 * 1000);
        endStr = `${tomorrow.getFullYear()}-${
          tomorrow.getMonth() + 1
        }-${tomorrow.getDate()} `;
      }

      // 获取开始时间、结束时间、现在时间的时间戳
      let startDate = new Date(startStr + startTime).getTime();
      let endDate = new Date(endStr + endTime).getTime();
      let nowDate = date.getTime();

      const s = startDate > endDate; // 判断开始时间否大于结束时间

      if (s) [startDate, endDate] = [endDate, startDate]; // 若开始时间否大于结束时间则交换值

      // 判断现在的时间是否在开始时间和结束时间之间，若s为true则结果取反
      if (nowDate >= startDate && nowDate <= endDate) {
        return !s;
      } else {
        return s;
      }
    };

    return {
      root,
      storeAreaList,
      storeList,
      bookForm,
      areaList,
      computers,
      rules,
      ruleFormRef,
      loading,
      dialogVisible,
      bookingPrice,
      userData,
      phone,
      storeStartTime,
      storeEndTime,
      storeChange,
      areaChange,
      disabledTime,
      onSubmit,
      handleClose,
      handleAffirm,
      timeChange,
      isBookingShow,
    };
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/base" as *;

.booking-bar {
  width: 100%;
  background: #0f1317;

  @include media("<tablet") {
    padding: 42px;
    background: rgb(30 41 59 / 80); // bg-slate-800
    max-height: 85dvh;
    overflow-y: auto;
  }

  .header {
    display: none;

    @include media("<tablet") {
      display: flex;
      justify-content: space-between;
      width: 100%;
      /* padding: 20px; */

      .booking-title {
        font-size: 1rem !important;
        font-weight: 700;
        color: #fff;
      }

      .cancel {
        font-size: 1rem !important;
        font-weight: 200;
        cursor: pointer;
      }
    }
  }

  :deep(.el-form) {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 8px;

    @include media("<tablet") {
      flex-direction: column !important;
      gap: 16px;
    }

    .form-data {
      position: relative;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      flex: 2;
      gap: 8px;

      @include media("<tablet") {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
      }

      .el-select {
        width: 100%;

        .el-select__selected-item:not(.is-transparent) {
          color: white;
        }
      }

      .el-input__wrapper,
      .el-select__wrapper {
        --el-input-text-color: #ffffff;
        background-color: #272c38;
      }

      .el-input--suffix,
      .el-select__wrapper {
        width: 100%;
        height: 2.5rem;
      }

      .el-input__inner {
        background-color: #272c38;
      }

      .el-form-item {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
      }
    }

    .form-buttons {
      flex-grow: 0;
      display: flex;
      gap: 8px;

      @include media("<tablet") {
        flex-direction: column;
        width: 100%;
        gap: 16px;
      }

      .el-form-item {
        margin: 0;

        .el-button {
          background-color: #ffb20e;
          color: #1b1f2a;
          font-weight: 900;
          height: 5vh;

          background-image: linear-gradient(
            to right,
            #ffb20e,
            #ffb20e,
            white,
            #ffb20e
          );
          background-size: 300% 100%;
          // color: white;
          transition: background-position 1s;

          &:hover {
            background-image: linear-gradient(
              to right,
              #ffb20e,
              white,
              #ffb20e,
              #ffb20e
            );
            background-position: 100% 0;
          }
        }

        a,
        button {
          width: 100%;
        }
      }
    }
  }

  .match-time {
    font-size: 14px;
    color: #808080;
    padding-top: 4px;
    display: flex;
    align-items: flex-start;

    .el-icon {
      margin-right: 6px;
    }
    p {
      padding-bottom: 6px;
    }
  }
}
</style>
