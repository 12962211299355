import * as actions from './actions'
import mutations from './mutations'
export default {
    namespaced: true,
    state:{
        shopList: null,
        bannerList: null,
        footBanner: null,
        shopDetail: null,
        cartList: [],
        cartStep:0,
        cartData:{
            goodsNum:0,
            totalMoney:0,
            totalTax:0
        },
        orderData:{
            goodsNum:0,
            totalMoney:0,
            totalTax:0
        }
    },
    actions,
    mutations
}