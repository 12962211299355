import * as actions from './actions'
import mutations from './mutations'
export default {
    namespaced: true,
    state:{
        gameList: null,
        bannerList: null,
    },
    actions,
    mutations
}