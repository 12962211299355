<template>
  <div class="head flex-b-c">
    <div class="left">
      <img
        v-if="!isShowNav"
        class="hambuger"
        alt=""
        src="@/assets/mobile/head/icon_more.png"
        @click="openNav"
        height="29"
        width="29"
      />
      <van-icon v-else name="cross" class="close" @click="isShowNav = false" />
    </div>

    <router-link to="/">
      <img
        alt=""
        class="logo"
        src="@/assets/mobile/head/icon_logo.png"
        height="29"
        width="29"
      />
    </router-link>

    <div class="right">
      <template v-if="!userData">
        <img
          alt=""
          src="@/assets/mobile/head/icon_user.png"
          @click="linkToLogin"
        />
      </template>
      <template v-else>
        <img
          :src="userData.memberPhoto"
          alt="Member Photo"
          class="user-avatar"
          ref="userPhoto"
          @click="toggleUserNav"
        />
      </template>
    </div>
  </div>
  <MobileNav ref="mobileNav" :isShow="isShowNav" />
  <MobileUserNav
    ref="mobileUserNav"
    :isShow="isShowUserNav"
    :userData="userData"
    @opneLink="closeUserNav"
  />
</template>

<script setup>
import { inject, ref, computed, toRaw } from "vue";
import { onClickOutside } from "@vueuse/core";
import { useStore } from "vuex";
import MobileNav from "@/components/web-header-footer/mobile-nav";
import MobileUserNav from "@/components/web-header-footer/mobile-user-nav";

const mobileNav = ref(null);
const mobileUserNav = ref(null);
const userPhoto = ref(null);
const emit = defineEmits(["handleLogin"]);
const store = useStore();

/** 获取用户数据*/
const userData = computed(() => store.state.user.userData);

/** 是否展示导航*/
const isShowNav = inject("isShowNav");
const isShowUserNav = ref(false);

/** 跳转到登录页面*/
const linkToLogin = () => {
  emit("handleLogin");
};

const openNav = () => {
  isShowNav.value = true;
};

const toggleUserNav = () => {
  isShowUserNav.value = !isShowUserNav.value;
};
const closeUserNav = () => {
  isShowUserNav.value = false;
};

onClickOutside(mobileNav, (event) => {
  isShowNav.value = false;
});

onClickOutside(mobileUserNav, (event) => {
  if (event.target === userPhoto.value) return;
  if (!isShowUserNav.value) return;
  isShowUserNav.value = false;
});
</script>

<style lang="scss" scoped>
@use "@/styles/_include-media.scss" as *;

.head {
  width: 100vw;
  padding: 37px 26px 0;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  backdrop-filter: blur(5px);
  mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
  height: 120px;
  display: flex;
  justify-content: space-between;

  .left,
  .right {
    img {
      width: 29px;
      aspect-ratio: 1;
      object-fit: contain;
      margin-right: 30px;
    }

    .user-avatar {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      cursor: pointer;
    }

    :deep(.van-icon) {
      font-size: 35px;
      margin-right: 30px;
    }
  }

  .left {
    .hambuger,
    .close {
      width: 29px;
      aspect-ratio: 1;
      object-fit: contain;
      cursor: pointer;
      color: white;
    }

    .close {
      left: -4px;
      top: -4px;
    }
  }

  .right img {
    margin-right: 0;
    margin-left: 25px;
  }

  .logo {
    width: 29px;
    height: 29px;
    aspect-ratio: 1;
    object-fit: contain;
  }
}
</style>
