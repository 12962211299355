import * as actions from './actions'
import mutations from './mutations'
export default {
    namespaced: true,
    state:{
        matchList: null,
        matchBanner: [],
        tabList: [],
        matchDetail:null,
        featured:null,
        ads:null,
        post:null,
        upcoming:null,
        cupSleeve:null,
    },
    actions,
    mutations
}