import * as types from "./mutation_types";

export default {
	[types.CUSTOMER_LIST](state: any, data: any) {
		state.customerList = data;
	},
	[types.GROUP_LIST](state: any, data: any) {
		state.groupList = data;
	},
	[types.GROUP_MEMBER_LIST](state: any, data: any) {
		state.groupMemberList = data;
	},
	[types.CONVERSATION_ID](state: any, data: any) {
		state.conversationID = data;
	},
	[types.MESSAGE_LIST](state: any, data: any) {
		if (data) {
			state.messageList.push(...data);
		} else {
			state.messageList = [];
		}
	},
	[types.SYSTEM_MESSAGE_COUNT](state: any, data: any) {
		state.systemMessageCount = data;
	},
};
