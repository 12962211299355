<template>
  <div class="create">
    <step1
      v-if="step === 1"
      @handleNext="handleNext"
      @handleBack="handleClick"
    ></step1>
    <step2
      v-if="step === 2"
      @handleSubmit="handleSubmit"
      @handleBack="step = 1"
    ></step2>
  </div>
</template>

<script setup>
import Step1 from "./step1";
import step2 from "./step2";
import { provide, ref, onMounted } from "vue";
import { useStore } from "vuex";

const visibleTransitionInit = { opacity: 0, y: 100 };
const visibleTransitionOnce = {
  opacity: 1,
  y: 0,
  transition: {
    type: "spring",
    stiffness: 250,
    damping: 25,
    mass: 0.5,
  },
};

const emit = defineEmits(["handleClick", "handleStepChanged"]);
const step = ref(1);
const store = useStore();
const btnState = ref(false);

provide("btnState", btnState);

onMounted(() => {
  emit("handleStepChanged", 1);
});

let step1Form = {};
/**
 * 下一步
 */
const handleNext = (data) => {
  emit("handleStepChanged", 2);
  step.value = 2;
  step1Form = data;
  console.log(step1Form);
};

/**
 * 注册成功返回登录
 */
const handleClick = () => {
  emit("handleClick", "0");
};

const handleSubmit = (data) => {
  btnState.value = true;
  let birth = new Date(step1Form.birth);
  let formData = Object.assign({ ...step1Form, ...data });
  let day = birth.getDate() > 9 ? birth.getDate() : "0" + birth.getDate();
  let month =
    birth.getMonth() + 1 > 9
      ? birth.getMonth() + 1
      : "0" + (birth.getMonth() + 1);
  let year = birth.getFullYear();
  formData.birth = day + "/" + month + "/" + year;
  store.dispatch("user/register", formData).then((res) => {
    btnState.value = false;
    if (res) {
      store.dispatch("http/setHttpState", {
        msg: "Thanks for Registering! Please login on the next page!!",
        state: true,
        dialogVisible: true,
      });
      handleClick();
    }
  });
};
</script>

<style scoped lang="scss">
.create {
  width: 100%;
  height: 100%;
}
</style>
