<template>
  <div class="game">
    <div class="title">
      <p class="title-label">Currently Trending Games</p>
      <router-link to="/games">
        <el-button class="text-black"> See All </el-button>
      </router-link>
    </div>
    <div class="list">
      <swiper
        :slides-per-view="2"
        :breakpoints="{
          480: {
            slidesPerView: 3,
            centeredSlides: false,
            spaceBetween: 8,
          },
          640: {
            slidesPerView: 4,
            centeredSlides: false,
          },
          1024: {
            slidesPerView: 6,
            centeredSlides: false,
          },
        }"
        :space-between="14"
        :loop="true"
        :centered-slides="false"
        :autoplay="true"
        :navigation="true"
      >
        <template
          v-for="(item, index) in gameList"
          :key="`${item.name}-${index}`"
        >
          <swiper-slide>
            <div class="detail">
              <img style="object-fit: contain; background-color: black" :src="item.cover" alt="" />
              <p>{{ item.name }}</p>
            </div>
          </swiper-slide>
        </template>
      </swiper>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import Swiper from "@/components/swiper";

export default {
  name: "trending-games",
  components: {
    swiper: Swiper,
  },
  setup() {
    const store = useStore();
    const gameList = computed(() => {
      if (store.state.home.gameList) 
      return store.state.home.gameList.slice(0, 20)
      return [];
    });
    onMounted(() => {
      store.dispatch("home/getGameList");
    });

    return {
      gameList,
    };
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/base" as *;

.game {
  padding: 30px 10.94vw;

  @include media("<tablet") {
    padding: 24px 0;
  }

  .title {
    text-align: left;

    .title-label {
      flex: 1;
    }
  }

  .list {
    height: fit-content;
    align-items: center;
    padding: 50px 0;
    display: block;

    @include media("<tablet") {
      padding: 24px 8vw;
    }

    .detail {
      position: relative;
      margin: 0 4px;
      text-align: center;
      width: fit-content;

      img {
        width: 145px;
        height: 256px;
        background: #bbbbbb;
        border-radius: 13px;
        transition: 0.2s;
        object-fit: cover;

        @include media("<tablet") {
          width: 100%;
        }
      }

      p {
        padding-top: 10px;
        font-size: 18px;
      }

      :hover {
        img {
          border: 2px solid #ffca0e;
          width: 150px;
          height: 252px;
        }
      }
    }
  }
}
</style>
