<template>
  <!--    book-->
  <div class="book min-h-[399.55px] w-[100vw]">
    <div class="title">
      <p>Book a visit</p>
    </div>
    <div class="flex-b-c booking-content">
      <swiper
        :centeredSlides="true"
        :initial-slide="1"
        :slides-per-view="2"
        :spaceBetween="18"
      >
        <swiper-slide>
          <div @click="showBooking = true">
            <img alt="" src="@/assets/mobile/home/rectangle-5@2x-min.png" width="150px" height="242.5px"/>
            <p class="pointer-events-none">SOLO</p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div @click="showBooking = true">
            <img fetchpriority="high" alt="" src="@/assets/mobile/home/rectangle-51@2x-min.png" width="150px" height="242.5px"/>
            <p>TEAM</p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSebmfmkYS24d5rggpdeVdjD5mn3e6IWH8fE0oeVf46gch6NTQ/viewform"
              target="_blank"
            >
              <img alt="" src="@/assets/mobile/home/rectangle-52@2x-min.png"  width="150px" height="242.5px"/>
              <p class="pointer-events-none">PARTY</p>
            </a>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>

  <van-popup
    v-model:show="showBooking"
    position="bottom"
    round
    :style="{ backgroundColor: '#1c1c1c' }"
    @open="isBookingClosed = false"
    @closed="isBookingClosed = true"
  >
    <BookingBar @onClose="showBooking = false" v-if="!isBookingClosed" />
  </van-popup>
</template>

<script setup>
import { ref } from "vue";
import Swiper from "@/components/swiper";
import BookingBar from "@/components/web/home/booking-bar";

const showBooking = ref(false);
const isBookingClosed = ref(false);
</script>

<style scoped lang="scss">
@use "@/styles/base" as *;

.book {
  padding: 24px 0;
  position: relative;

  .title {
    margin: 0 0 24px 0;
  }

  img {
    width: 240px;
    margin-bottom: 40px;
  }

  .booking-content {
    @include media("<tablet") {
      padding: 0 8vw;
    }

    div {
      position: relative;
    }

    p {
      @include text-6xl;
      @include font-black;
      position: absolute;
      bottom: 80px;
      width: 100%;
      text-align: center;
      left: 0;
      background: linear-gradient(270deg, #d98080, #612aca);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke: 0.5px #ffffff;
      pointer-events: none;

      @include media("<phone") {
        @include text-4xl;
        text-align: right;
      }
    }
  }

  :deep(.filed) {
    div {
      width: 140px;
      height: 70px;
      border-radius: 16px 16px 16px 16px;
      opacity: 1;
      border: 3px solid #3e4a5a;
      padding: 0 20px;
      line-height: 70px;
      margin-right: 28px;
      background: none;
      font-size: 28px;
      text-align: center;
    }

    .width {
      width: 220px;
      margin-bottom: 40px;
    }
  }

  .van-button {
    width: 165px;
    height: 70px;
    background: #ffc70e;
    border-radius: 15px;
    position: absolute;
    font-size: 28px;
    bottom: 41px;
    right: 45px;
    outline: none;
    border: none;
    font-weight: bolder;
  }
}
</style>
