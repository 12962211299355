<template>
  <swiper-container
    ref="swiperRef"
    v-bind="props"
    :init="false"
    @swiper="handleSwiper"
  >
    <slot></slot>
  </swiper-container>
</template>

<script>
import { onMounted, ref } from "vue";
import {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  FreeMode,
  Thumbs,
} from "swiper";

export default {
  name: "swiper",
  emits: ["swiper"],
  props: {
    slidesPerView: Number,
    breakpoints: Object,
    spaceBetween: Number,
    loop: Boolean,
    centeredSlides: Boolean,
    autoplay: undefined,
    freeMode: Boolean,
    navigation: Boolean,
  },
  setup(props, { emit }) {
    const swiperRef = ref();
    onMounted(() => {
      const params = {
        module: [A11y, Autoplay, Navigation, Pagination, FreeMode, Thumbs],
        // array with CSS styles
        injectStyles: [
          `
          .swiper {

            .swiper-button-next,
            .swiper-button-prev {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              font-size: 22px;
              font-weight: bold;
              color: #ffb20e;
            }

            .swiper-pagination-bullet-active {
              background: #ffb20e;
            }
          }

          .swiper-wrapper {
            color: #fff;
            transition-timing-function: linear !important;
            -o-transition-timing-function: linear !important;
            -webkit-transition-timing-function: linear !important;
          }
          `,
        ],
      };

      Object.assign(swiperRef.value, params);

      swiperRef.value.initialize();
    });

    const handleSwiper = (args) => emit("swiper", args);

    return { swiperRef, props, handleSwiper };
  },
};
</script>
