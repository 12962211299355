import * as actions from './actions'
import mutations from './mutations'
export default {
    namespaced: true,
    state:{
        newsList: null,
        newsDetail: null
    },
    actions,
    mutations
}