<template>
  <div
    v-if="userData"
    :class="`nav ${
      isShow ? 'translate-x-0' : 'translate-x-[100vw]'
    } transition-transform`"
  >
    <p class="title">User Settings</p>
    <el-menu>
      <router-link to="/user?type=first" @click="emit('opneLink')">
        <el-menu-item class="nav-item" index="4">
          <span>My Account</span>
          <van-icon name="arrow" />
        </el-menu-item>
      </router-link>
      <router-link to="/user?type=second" @click="emit('opneLink')">
        <el-menu-item class="nav-item" index="1">
          <span>Join Game</span>
          <van-icon name="arrow" />
        </el-menu-item>
      </router-link>
      <router-link to="/user?type=fourth" @click="emit('opneLink')">
        <el-menu-item class="nav-item" index="3">
          <span>Address</span>
          <van-icon name="arrow" />
        </el-menu-item>
      </router-link>
      <router-link to="/user?type=five" @click="emit('opneLink')">
        <el-menu-item class="nav-item" index="5">
          <span>Vouchers</span>
          <van-icon name="arrow" />
        </el-menu-item>
      </router-link>
      <router-link to="/user?type=six" @click="emit('opneLink')">
        <el-menu-item class="nav-item" index="6">
          <span>Booking</span>
          <van-icon name="arrow" />
        </el-menu-item>
      </router-link>
      <router-link to="/user?type=seven" @click="emit('opneLink')">
        <el-menu-item class="nav-item" index="7">
          <span>Game Account</span>
          <van-icon name="arrow" />
        </el-menu-item>
      </router-link>
      <router-link to="/user-setting" @click="emit('opneLink')">
        <el-menu-item class="nav-item" index="6">
          <span>Setting</span>
          <van-icon name="arrow" />
        </el-menu-item>
      </router-link>
    </el-menu>

    <div class="btn" @click="handleOut">LOG OUT</div>
  </div>
</template>

<script setup>
const { userData, isShow } = defineProps(["userData", "isShow"]);
const emit = defineEmits(["opneLink"]);
const handleOut = () => {
  sessionStorage.clear();
  window.location.href = "/";
};
</script>

<style lang="scss" scoped>
@use "@/styles/base.scss" as *;

.nav {
  width: 40dvw;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  display: flex;
  flex-direction: column;
  margin-top: 90px;
  z-index: 100;

  @include media("<tablet") {
    width: 100dvw;
  }

  .title {
    @include text-2xl;
    @include font-bold;
    text-align: left;
    color: white;
    padding: 20px;
  }

  .nav-item {
    @include text-lg;
    @include font-bold;
    position: relative;
    padding: 20px 30px;
    color: white;
    border-bottom: 1px solid #3e4a5a;

    display: flex;
    place-content: space-between;
  }
}
</style>
