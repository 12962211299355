<template>
  <div :class="isLogin ? 'web-index animated' : 'web-index'">
    <div class="content">
      <header-web
        v-if="headShow"
        class="header-show"
        @handleLogin="handleLogin"
      ></header-web>
      <div class="pt-20 md:pt-24">
        <router-view :key="route.fullPath"></router-view>
      </div>
      <footer-web></footer-web>
    </div>
    <div class="login">
      <User-set @handleClose="handleClose"></User-set>
    </div>
  </div>

  <el-dialog
    v-model="update"
    width="860PX"
    append-to-body
    center
    :close-on-click-modal="false"
  >
    <update-user @handleSkip="update = false"></update-user>
  </el-dialog>
  <el-drawer
    v-model="drawer"
    direction="btt"
    :with-header="false"
    :show-close="true"
    :size="120"
    :close-on-click-modal="false"
    :wrapperClosable="false"
    class="cookie-el-drawer"
    modal-class="cookie-drawer"
  >
    <div class="cookie-container">
      <div class="description">
        <span
          >By clicking “Allow All”, you agree to the storing of cookies on your
          device to enhance site navigation, analyze site usage, and assist in
          our marketing efforts.
          <span
            class="cursor-pointer underline text-[#216afa]"
            @click="openPolicy('/static/policy/SideQuest-Cookie-Policy.pdf')"
            >Cookie Notice</span
          ></span
        >
      </div>
      <div class="buttons">
        <el-button
          class="button-disable"
          type="warning"
          round
          @click="closeDrawer"
        >
          Disable All
        </el-button>
        <el-button
          class="button-allow"
          type="success"
          round
          @click="closeDrawer"
        >
          Allow All
        </el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { computed, inject, onMounted, provide, ref, watch } from "vue";
import UserSet from "@/components/web/user";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import UpdateUser from "@/components/web/user/update-user";
import HeaderWeb from "@/components/web-header-footer/header-web";
import FooterWeb from "@/components/web-header-footer/footer-web";

export default {
  name: "index-web",
  components: { FooterWeb, HeaderWeb, UserSet, UpdateUser },
  setup() {
    const store = useStore();
    const route = useRoute();
    store.dispatch("user/getUSerData");
    const isLogin = computed(() => store.state.http.loginState);
    const userState = computed(() => store.state.user.userData);
    const drawer = ref(true);
    const update = ref(false);
    const step = ref("0");
    provide("userState", userState);

    const headShow = inject("headShow");
    watch(userState, (v) => {
      if (v) {
        if (v.admin === 2) {
          update.value = true;
        }
      }
    });
    provide("update", update);

    const joinShow = ref(true);
    provide("joinShow", joinShow);

    onMounted(() => {
      drawer.value = !localStorage.getItem("poped");
      if (route.path === "/dctopup") {
        drawer.value = false;
      }
    });

    /**
     * 登录
     */
    const handleLogin = () => {
      store.dispatch("http/setLoginState", true);
    };

    /**
     * 关闭登录
     */
    const handleClose = () => {
      store.dispatch("http/setLoginState", false);
      update.value = false;
    };

    const openPolicy = (url) => {
      window.open("/static/pdfjs/web/viewer.html?file=" + url);
    };
    const closeDrawer = () => {
      localStorage.setItem("poped", "1");
      drawer.value = false;
    };
    return {
      isLogin,
      step,
      drawer,
      update,
      route,
      headShow,
      closeDrawer,
      handleLogin,
      handleClose,
      openPolicy,
    };
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/base" as *;

.web-index {
  width: 100%;
  height: 100%;
  background: #f5cb49;
  animation: 0.5s;
  position: relative;

  .header-show {
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
    transition: 0.5s;
    z-index: 12;
  }

  .content {
    width: 100%;
    height: 100%;
    transition: 0.5s;
    background: #0f1317;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
  }

  .login {
    width: 0;
    height: 20px;
    position: absolute;
    transition: 0.5s;
    visibility: hidden;
    background: #666666;
    top: 0;
    right: 0;
    opacity: 0;
    overflow: hidden;
  }
}

.animated {
  .header-show {
    opacity: 0;
    left: 0;
    z-index: 0;
  }

  .content {
    left: 50px;
    top: 50px;
    overflow: hidden;
    border-radius: 6px;
    transform: scale(0.6);
    opacity: 0;
  }

  .login {
    width: 100vw;
    right: 50%;
    transform: translateX(50%);
    visibility: initial;
    height: 100vh;
    opacity: 1;

    @include media("<tablet") {
      top: 0;
      right: 0;
      transform: unset;
      border-radius: unset;
      height: 100vh;
    }
  }
}

.cookie-drawer {
  .el-drawer {
    @include media("<phone") {
      height: 240px;
    }

    .cookie-container {
      @include text-base;
      padding: 25px;
      display: flex;
      gap: 24px;

      .description {
        flex: 1;
      }

      @include media("<phone") {
        flex-direction: column;
      }

      .buttons {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .button-allow {
          margin: 0;
        }
      }
    }
  }
}
</style>

<style lang="scss">
@use "@/styles/base" as *;

.cookie-el-drawer {
  @include media("<phone") {
    height: 240px !important;
  }
}
</style>
