<template>
  <div class="home" v-loading.fullscreen.lock="loading">
    <div
      class="anchor"
      @click="goAnchor('#book')"
      :class="{ scroll: !isBookingShow }"
    >
      <upArrowSvg
        class="bg-yellow-500 border-yellow-500 border-2 rounded-full w-16 h-16"
        fill="black"
      />
    </div>
    <!-- <a class="anchor" style="top: 240px; font-size: 14.225px;" href="https://forms.gle/URqJNCW7LS2bCdWU8" target="_blank"><img src="@/assets/web/party-book.png" alt=""> <span>Party Booking</span></a> -->

    <div class="pb-[30px] max-md:hidden">
      <scroll-parallax class="parallax" :speed="0.4" :down="true" v-if="adList">
        <el-carousel
          trigger="click"
          height="78vh"
          :interval="5000"
          :pause-on-hover="false"
          @change="handleCarouselChange"
        >
          <!-- <el-carousel-item v-for="item in adList" :key="item">
            <img class="bg" :src="item.url" alt="">
          </el-carousel-item> -->
          <el-carousel-item :key="1">
            <div
              class="text-5xl leading-tight text-slate-100 absolute top-36 left-32 z-10 font-black w-[35vw] text-left"
              :class="{ slideUpElement: animated }"
            >
              E-Sports Elite
              <span class="text-yellow-500">Viewing Party</span>: Rise to the
              Top
            </div>
            <img class="bg w-full h-full" src="@/assets/web/home1.png" alt="" />
          </el-carousel-item>
          <el-carousel-item :key="2">
            <div
              class="text-5xl leading-tight text-slate-100 absolute top-36 left-32 z-10 font-black w-[35vw] text-left"
              :class="{ slideUpElement: animated }"
            >
              <span class="text-yellow-500">Game</span>, Gobble, and Giggle:
              Friends & Feasts Await
            </div>
            <img class="bg w-full h-full" src="@/assets/web/home2.png" alt="" />
          </el-carousel-item>
          <el-carousel-item :key="3">
            <div
              class="text-5xl leading-tight text-slate-100 absolute top-36 left-32 z-10 font-black w-[35vw] text-left"
              :class="{ slideUpElement: animated }"
            >
              <span class="text-yellow-500">Cupsleeves</span>:
              <br />
              <span>The K-Pop Fan Gatherings</span>
            </div>
            <img class="bg w-full h-full" src="@/assets/web/home4.png" alt="" />
          </el-carousel-item>
        </el-carousel>
      </scroll-parallax>
    </div>

    <div class="home-content">
      <div
        class="area lg:mb-16 min-h-[399.55px] w-[100vw]"
        id="book"
        v-motion
        :initial="visibleTransitionInit"
        :visible-once="visibleTransitionOnce"
      >
        <div class="title">
          <p>Stores</p>
        </div>
        <div class="max-md:w-[100vw] max-md:overflow-scroll">
          <div class="list">
            <div
              class="h-fit"
              v-for="(item, index) in storeList"
              v-bind:key="`store-${index}`"
            >
              <router-link :to="`/store/${item.id}`" :key="index">
                <div>
                  <img
                    class="store-bg"
                    :src="require('@/assets/web/store.png')"
                    alt=""
                  />
                  <img class="store-img" :src="item.headImage" alt="" />
                  <p>{{ item.name }}</p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="block md:hidden">
        <BookAVisit
          v-motion
          :initial="visibleTransitionInit"
          :visible-once="visibleTransitionOnce"
        />
      </div>
      <RecommendedArticles
        v-motion
        :initial="visibleTransitionInit"
        :visible-once="visibleTransitionOnce"
      />
      <UpcomingTournaments
        v-motion
        :initial="visibleTransitionInit"
        :visible-once="visibleTransitionOnce"
      />
      <div class="block md:hidden">
        <DownloadApp
          v-motion
          :initial="visibleTransitionInit"
          :visible-once="visibleTransitionOnce"
        />
      </div>
      <TrendingGames
        v-motion
        :initial="visibleTransitionInit"
        :visible-once="visibleTransitionOnce"
      />
      <div class="hidden md:block">
        <BookingBar open />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import upArrowSvg from "@/components/web/home/upArrowSvg";
import BookingBar from "@/components/web/home/booking-bar";
import RecommendedArticles from "@/components/web/home/recommended-articles";
import UpcomingTournaments from "@/components/web/home/upcoming-tournaments";
import TrendingGames from "@/components/web/home/trending-games";
import DownloadApp from "@/components/web/home/mobile/download-app";
import BookAVisit from "@/components/web/home/mobile/booking/book-a-visit";

const visibleTransitionInit = { opacity: 0, y: 100 };
const visibleTransitionOnce = {
  opacity: 1,
  y: 0,
  transition: {
    type: "spring",
    stiffness: 250,
    damping: 25,
    mass: 0.5,
  },
};

export default {
  name: "home-web",
  components: {
    upArrowSvg,
    BookingBar,
    RecommendedArticles,
    UpcomingTournaments,
    TrendingGames,
    BookAVisit,
    DownloadApp,
  },
  setup() {
    const store = useStore();

    const animated = ref(true);
    const isBookingShow = ref(false);

    const loading = ref(false);

    onMounted(() => {
      // store.dispatch('home/getHomeList')
      store.dispatch("home/getAdList");
      store.dispatch("home/getGameList");
      store.dispatch("home/getHeadline");
      store.dispatch("home/getMatch");
      store.dispatch("home/getNews");
      store.dispatch("home/getStoreAreaList");
      store.dispatch("home/getStoreList");
    });
    const adList = computed(() => store.state.home.adList);
    const storeList = computed(() => store.state.home.storeList);

    onMounted(() => {
      window.addEventListener("scroll", scrollTop, true);
    });

    /**
     * @description 锚链接滚动过渡
     * @param {String} selector   元素ID or 定位点
     */
    const goAnchor = (selector) => {
      // 移动距离
      let top = 0;
      // 当前滚动条位置
      const scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      // 若为指定距离
      if (typeof selector === "number") {
        top = selector - scrollTop;
      } else {
        const anchor = document.querySelector(selector) || {
          offsetTop: 0,
        };
        top = anchor.offsetTop - scrollTop;
      }
      window.scrollBy({ top, behavior: "smooth" });
    };

    const handleCarouselChange = () => {
      animated.value = true;
      setTimeout(() => {
        animated.value = false;
      }, 1000);
    };

    const scrollTop = () => {
      let scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      isBookingShow.value = scroll > 200;
    };

    return {
      loading,
      adList,
      goAnchor,
      storeList,
      isBookingShow,
      handleCarouselChange,
      animated,
      scrollTop,
      visibleTransitionInit,
      visibleTransitionOnce,
    };
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/_include-media" as *;

.scroll {
  opacity: 0;
  visibility: hidden;
  animation: booking-bar 0.4s ease-in-out;
}
.home {
  width: 100%;
  background: #0f1317;

  .anchor {
    position: fixed;
    bottom: 80px;
    right: 20px;
    z-index: 9;
    width: 120px;
    border-radius: 10px;
    cursor: pointer;
    img {
      width: 100%;
    }
    span {
      position: absolute;
      display: block;
      text-align: center;
      width: 100%;
      color: #ffffff;
      font-weight: bolder;
      left: 0;
      top: 42px;
    }
  }
  .bg {
    width: 100%;
    z-index: 0;
    mask-image: linear-gradient(
        270deg,
        rgba(0, 0, 0, 1) 30%,
        rgba(255, 255, 255, 0) 60%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 0%),
      linear-gradient(300deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 60%);
  }

  .slideUpElement {
    opacity: 1;
    animation: slide-up 0.85s ease-in-out;
  }

  :deep(.el-input__wrapper) {
    background-color: #272c38;
  }

  :deep(.el-input--suffix) {
    width: 8vw;
    height: 5vh;
  }

  :deep(.el-input__inner) {
    background-color: #272c38;
  }

  :deep(.el-form) {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  :deep(.el-form-item) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;

    .el-button {
      background-color: #ffb20e;
      color: #1b1f2a;
      font-weight: 900;
      height: 5vh;

      background-image: linear-gradient(
        to right,
        #ffb20e,
        #ffb20e,
        white,
        #ffb20e
      );
      background-size: 300% 100%;
      // color: white;
      transition: background-position 1s;

      &:hover {
        background-image: linear-gradient(
          to right,
          #ffb20e,
          white,
          #ffb20e,
          #ffb20e
        );
        background-position: 100% 0;
      }
    }
  }

  /* :deep(.el-carousel) { */
  /* height: 100vh; */
  /* } */

  :deep(.el-carousel-item) {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 100%;
    height: 100vh;
  }

  :deep(.el-carousel__indicators--horizontal) {
    left: unset;
    right: 0;
    bottom: 24px;
    text-align: right;

    .el-carousel__button {
      height: 8px;
      border-radius: 8px;
    }

    .is-active {
      .el-carousel__button {
        width: 64px;
        background: #ffb20e;
      }
    }
  }

  :deep(.home-content) {
    color: #ffffff;
    z-index: 8;
    position: relative;

    .title {
      display: flex;
      justify-content: space-between;
      line-height: 48px;

      @include media("<tablet") {
        padding: 0 8vw;
        line-height: 1.1;
        text-align: left;
      }

      p {
        font-size: 24px;
        font-weight: 900;
        text-transform: uppercase;
      }

      .el-input__wrapper {
        width: 120px !important;
        background: #272c38;
        height: 48px;
        box-shadow: none;
      }

      .el-form-item__content {
        .el-input--prefix {
          height: 50px;
          width: 140px !important;
        }
      }

      .el-input__inner {
        color: #ffffff;
        background-color: #272c38;
      }

      .el-button {
        background: #ffd40e;
        border: none;
        border-radius: 12px;
        padding: 0 20px;
        height: 48px;
        font-weight: 900;
        text-transform: uppercase;
        a {
          color: black !important;
        }
      }
    }

    .list {
      display: flex;
      justify-content: flex-start;

      a {
        color: #ffffff;
      }
    }

    .area {
      padding: 30px 10.94vw;

      @include media("<tablet") {
        padding: 24px 0;
      }

      .title {
        @include media("<tablet") {
          padding: 0 0 0 8vw;
        }

        .el-form--inline .el-form-item {
          margin-right: 20px;
        }

        .el-input__wrapper {
          border: 1px solid #ffffff;
          border-radius: 6px;
        }

        .booking-width {
          width: 120px;
          margin-right: 20px;
          .el-input--suffix {
            width: 120px !important;
          }
          .el-input__inner {
            width: 100px !important;
          }
        }
      }

      .list {
        position: relative;
        height: 466px;
        padding: 50px 0;
        justify-content: space-between;

        @include media("<tablet") {
          display: flex;
          width: fit-content;
          padding: 24px 8vw 12px;
          gap: 12px;
          height: fit-content !important;
        }

        div {
          position: relative;
          width: 15vw;
          text-align: center;

          @include media("<tablet") {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 150px;
          }

          .store-bg {
            position: absolute;
            width: 100%;
            z-index: 2;
            left: 0;
            // top: -4px;
          }

          .store-img {
            // width: 96%;
            border-radius: 18px;
            transition: 0.2s;
            scale: 0.96;
            transform: translateY(3px);
            transform-origin: 50% 0;

            @include media("<desktop") {
              border-radius: 12px;
            }
          }

          p {
            font-size: 1.35rem;
            padding: 60px 0px 20px;
            // width: calc(100% - 40px);

            @include media("<desktop") {
              font-size: 1rem;
              padding: 40px 0px 20px;
              width: unset;
            }
          }
        }
      }

      .el-button {
        font-weight: bolder;
        font-size: 14px;
        color: black;
        text-transform: uppercase;
      }
    }
  }

  .match-time {
    font-size: 14px;
    color: #808080;
    padding-top: 4px;
    display: flex;
    align-items: flex-start;

    .el-icon {
      margin-right: 6px;
    }
    p {
      padding-bottom: 6px;
    }
  }

  @keyframes slide-up {
    0% {
      opacity: 0;
      transform: translateY(150px);
    }
    30% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes booking-bar {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
</style>
