import * as actions from './actions'
import mutations from './mutations'

export default {
    namespaced: true,
    state: {
        httpData: {
            msg:'',
            state:false,
            dialogVisible:false,
        },
        loginState:false
    },
    actions,
    mutations
}