import * as actions from './actions'
import mutations from './mutations'

export default {
    namespaced: true,
    state: {
        adList: null,
        bestList: null,
        gameList: null,
        headline: null,
        match: null,
        storeAreaList: null,
        storeList: null,
        news: null,
        loginStatus:false
    },
    actions,
    mutations
}
