import * as types from './mutation_types'
import http from "@/utils/request";

export const getMatchList = (store:any,params:object) => {
    store.commit(types.MATCH_LIST, null)
    // http({path: '/web/index/match/index', method: 'get',params:params}).then(res => {
    return http({path: '/app/events/26/webActivities', method: 'get',params:params}).then(res => {
        store.commit(types.MATCH_LIST, res)
        return res
    })
}
export const getEventTab = (store:any) => {
    store.commit(types.TAB_LIST, null)
    http({path: '/app/events/26/eventTab', method: 'get'}).then(res => {
        store.commit(types.TAB_LIST, res)
    })
}
export const getMatchDetail = (store:any,id:string) => {
    store.commit(types.MATCH_DETAIL, null)
    return http({path: `/app/events/26/match/detail/${id}`, method: 'get'}).then(res => {
        store.commit(types.MATCH_DETAIL, res)
        return res
    })
}

export const getEventDetail = (store:any,id:string) => {
    store.commit(types.MATCH_DETAIL, null)
    return http({path: `/app/events/26/event/detail/${id}`, method: 'get'}).then(res => {
        store.commit(types.MATCH_DETAIL, res)
        return res
    })
}

export const getWebMatchList = (store:any,params:object) => {
    store.commit(types.MATCH_LIST_WEB, null)
    return http({path: '/web/match/webMatchList', method: 'get',params:params}).then(res => {
        store.commit(types.MATCH_LIST_WEB, res)
        return res
    })
}

export const getWebMatchDetail = (store:any,id:string) => {
    store.commit(types.MATCH_DETAIL, null)
    http({path: `web/index/match/detail/${id}`, method: 'get'}).then(res => {
        store.commit(types.MATCH_DETAIL, res)
    })
}

export const getMatchPersonal = (store:any,data:any) => {
    return http({path: `web/match/personal/info`, method: 'get',params: data})
}
export const joinMatch = (store:any,data:object) => {
    return http({path: `/web/match/personal/attend`, method: 'post',params:data}).then(res => {
        return res
    })
}
export const createTeam = (store:any,data:object) => {
    return http({path: `/web/match/team/create`, method: 'post',params:data}).then(res => {
        return res
    })
}

export const joinTeam = (store:any,data:object) => {
    return http({path: `/web/match/team/attend`, method: 'post',params:data}).then(res => {
        return res
    })
}


export const getMatchTree = (store:any,data:object) => {
    return http({path: `/web/match/course/info`, method: 'get',params:data}).then(res => {
        return res
    })
}

export const getEventCoupons = (store:any) => {
    return http({path: `/app/coupon/eventCoupons`, method: 'get'}).then(res => {
        return res
    })
}

export const selectCoupon = (store:any,data:object) => {
    return http({path: `/app/coupon/selectCoupon`, method: 'get',params:data}).then(res => {
        return res
    })
}
