import * as types from "./mutation_types"

export default {
    [types.STORE_DETAIL] (state:any,data={}) {
        state.storeDetail = data
    },
    [types.STORE_LIST] (state:any,data={}) {
        state.storeList = data
    }
}
