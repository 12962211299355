import * as types from "./mutation_types"
export default {
    [types.MATCH_LIST] (state:any,data:any) {
        state.matchList = data!==null?data.matchList:null
        state.matchBanner = data!==null?data.ads:[]
    },
    [types.TAB_LIST] (state:any,data:any) {
        state.tabList = data
    },
    [types.MATCH_DETAIL] (state:any,data:any) {
        if(data){
            const newData = JSON.parse(data.match.locations).map((item:any) => {
                if(item.id === 0){
                    item.name = 'Online'
                }
                return item
            })
            data.match.locations = JSON.stringify(newData)
            state.matchDetail = data
        }else {
            state.matchDetail = null
        }
    },
    [types.MATCH_LIST_WEB] (state:any,data:any) {
        state.featured = data!==null?data.featured:null
        state.ads = data!==null?data.ads:[]
        state.post = data!==null?data.post:[]
        state.upcoming = data!==null?data.upcoming:[]
        state.cupSleeve = data!==null?data.cupsleeve:[]
    },
}
