<template>
  <div style="width: 100%; height: 100%">
    <router-view />
    <http-state></http-state>
  </div>
</template>
<script>
import { onMounted, onDeactivated, ref, provide } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "HomeView",
  setup() {
    const router = useRouter();

    const headShow = ref(true);
    provide("headShow", headShow);
    const isShowNav = ref(false);
    provide("isShowNav", isShowNav);

    const updateWindowDimensions = () => {
      const screenWidth = 1920;
      const scale = screenWidth / 16;
      const htmlWidth =
        document.documentElement.clientWidth || document.body.clientWidth;
      const htmlDom = document.getElementsByTagName("html")[0];
      htmlDom.style.fontSize = htmlWidth / scale + "px";
    };
    onDeactivated(() => {
      // window.removeEventListener("resize", updateWindowDimensions);
    });
    onMounted(() => {
      // window.addEventListener("resize", updateWindowDimensions);
      let token = getQueryVariable().token;
      if (token) {
        sessionStorage.setItem("Authorization", token);
      }
      let data = location.pathname.split("/");
      // if(_isMobile()){
      //   if(location.href.indexOf('h5') === -1 && location.href.indexOf('share') === -1 && location.href.indexOf('transfer') === -1 && location.href.indexOf('activity') === -1){
      //     location.href = 'https://m.sidequesthub.com/'
      //   }
      // }else {
      if (
        location.href.indexOf("activities") > 0 &&
        !!Number(data[data.length - 1])
      ) {
        location.href = "/tournaments/detail/" + data[data.length - 1];
      }
      // }

      const head = getQueryVariable().head;
      if (head === "false") {
        headShow.value = false;
      }
    });

    const getQueryVariable = () => {
      let href = window.location.href;
      let query = href.substring(href.indexOf("?") + 1);
      let vars = query.split("&");
      let obj = {};
      for (var i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        obj[pair[0]] = pair[1];
      }
      return obj;
    };

    /**
     * 判断手机端
     * @returns {RegExpMatchArray}
     * @private
     */
    const _isMobile = () => {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
      );
    };
    return {};
  },
};
</script>
<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  font-size: 100%;
  background: #000000;
}
@font-face {
  font-family: "font";
  src: url("./assets/font/font.ttf");
}
#app {
  font-family: font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
}
* {
  padding: 0;
  margin: 0;
  min-width: 0;
}
textarea,
input {
  font: inherit;
  letter-spacing: inherit;
  word-spacing: inherit;
}
.parallax {
  transition: 0.5s ease-out;
}

.coupon-option {
  display: flex;
  justify-content: space-between;
}
</style>
