import * as types from "./mutation_types"

export default {
    [types.HOME_DATA] (state:any,data:any) {
        if(data){
            state[data.type] = data.data[data.type]
        }
    },
    [types.LOGIN_STATUS] (state:any,data:boolean) {
        state.loginStatus = data
    },
}
