import * as types from "./mutation_types";
import http from "@/utils/request";

// tim列表
export const setCustomerList = (store: any, data: any) => {
	store.commit(types.CUSTOMER_LIST, data);
};
export const setGroupList = (store: any, data: any) => {
	store.commit(types.GROUP_LIST, data);
};

export const setMessageList = (store: any, data: any) => {
	store.commit(types.MESSAGE_LIST, data);
};

export const setConversationId = (store: any, data: any) => {
	store.commit(types.CONVERSATION_ID, data);
};

export const setSystemMessageCount = (store: any, data: any) => {
	store.commit(types.SYSTEM_MESSAGE_COUNT, data);
};
export const ukChangeId = (store: any, data: any) => {
	return http({ url: `/web/sidekick/social/uk2id`, method: "get", data });
};
