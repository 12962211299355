<template>
  <div class="step1">
    <p>UPDATE ACCOUNT</p>

    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      class="form"
      :inline="true"
    >
      <el-form-item prop="firstName">
        <div class="label-name">FirstName</div>
        <el-input
          v-model="ruleForm.firstName"
          auto-complete="off"
          placeholder="Enter your firstName"
        />
      </el-form-item>
      <el-form-item prop="lastName">
        <div class="label-name">LastName</div>
        <el-input
          v-model="ruleForm.lastName"
          auto-complete="off"
          placeholder="Enter your lastName"
        />
      </el-form-item>
      <el-form-item prop="nickname">
        <div class="label-name">Nickname</div>
        <el-input
          v-model="ruleForm.nickname"
          auto-complete="off"
          placeholder="Enter your nickname"
        />
      </el-form-item>
      <el-form-item prop="sex">
        <div class="label-name">Gender</div>
        <el-select v-model="ruleForm.sex" placeholder="Choose your gender">
          <el-option label="Male" value="0"></el-option>
          <el-option label="Female" value="1"></el-option>
          <el-option label="Nonconforming" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="birth">
        <div class="label-name">Birthday</div>
        <el-date-picker
          v-model="ruleForm.birth"
          type="date"
          format="DD/MM/YYYY"
          placeholder="Enter your Birthday"
          @change="timeChange"
        />
      </el-form-item>
      <el-form-item prop="phone">
        <div class="label-name">Phone</div>
        <el-input
          v-model="ruleForm.phone"
          maxlength="13"
          auto-complete="off"
          placeholder="Enter your phone number"
          onkeyup="this.value=this.value.replace(/\D/g,'')"
          onafterpaste="this.value=this.value.replace(/\D/g,'')"
        />
      </el-form-item>
      <el-form-item prop="password">
        <div class="label-name">Password</div>
        <el-input
          v-model="ruleForm.password"
          auto-complete="off"
          placeholder="Enter your password"
        />
      </el-form-item>
      <el-form-item prop="payCode">
        <div class="label-name">Payment Pin</div>
        <el-input
          v-model="ruleForm.payCode"
          :maxlength="6"
          onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
          auto-complete="off"
          placeholder="Enter your payment pin"
        />
        <p style="padding-top: 4px; color: #efefef">
          *Only 6 numbers accepted as your payment pin
        </p>
      </el-form-item>
    </el-form>
    <div>
      <el-checkbox v-model="ruleForm.newsLetter"
        >Sign up to our newsletters for updates and offers</el-checkbox
      >
      <el-checkbox v-model="ruleForm.policy">
        I agree to the UK SideQuest
        <a
          style="color: #fff833"
          href="/static/pdfjs/web/viewer.html?file=/static/policy/SideQuest-Privacy-Policy.pdf"
          target="_blank"
        >
          Privacy Policy
        </a>
        and
        <a
          href="/static/pdfjs/web/viewer.html?file=/static/policy/SideQuest-Terms-of-Service.pdf"
          target="_blank"
          style="color: #fff833"
        >
          Service Agreement
        </a>
      </el-checkbox>
    </div>
    <div class="btn">
      <el-button
        type="primary"
        style="margin-right: 16px; background: #bbbbbb"
        @click="handleBack"
        >Skip</el-button
      >
      <el-button
        :disabled="btnState"
        type="primary"
        style="margin-right: 16px"
        @click="onSubmit"
        >Submit</el-button
      >
    </div>
  </div>
</template>

<script>
import { computed, inject, reactive, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "index-create",
  setup(props, { emit }) {
    const store = useStore();
    const rules = reactive({
      lastName: [
        {
          required: true,
          message: "last name can not be empty",
          trigger: "blur",
        },
        {
          min: 2,
          max: 100,
          message: "last name character length is between 2 and 100",
          trigger: "blur",
        },
      ],
      firstName: [
        {
          required: true,
          message: "first name must have value",
          trigger: "blur",
        },
        {
          min: 2,
          max: 100,
          message: "first name character length is between 2 and 100",
          trigger: "blur",
        },
      ],
      nickname: [
        {
          required: true,
          message: "nick name cannot be empty",
          trigger: "blur",
        },
      ],
      sex: [
        { required: true, message: "sex cannot be empty", trigger: "blur" },
      ],
      password: [
        {
          required: true,
          message: "password cannot be empty",
          trigger: "blur",
        },
        {
          min: 6,
          max: 20,
          message: "password character length is between 6 and 20",
          trigger: "blur",
        },
      ],
      password2: [
        {
          required: true,
          message: "password cannot be empty",
          trigger: "blur",
        },
        {
          min: 6,
          max: 20,
          message: "password character length is between 6 and 20",
          trigger: "blur",
        },
      ],
      payCode: [
        {
          required: true,
          message: "payment pin cannot be empty",
          trigger: "blur",
        },
        {
          min: 6,
          max: 6,
          message: "Only 6 numbers accepted as your payment pin",
          trigger: "blur",
        },
      ],
      payCode2: [
        {
          required: true,
          message: "payment pin cannot be empty",
          trigger: "blur",
        },
        {
          min: 6,
          max: 6,
          message: "Only 6 numbers accepted as your payment pin",
          trigger: "blur",
        },
      ],
      phone: [
        {
          required: true,
          pattern: /^[0-9]*$/,
          message: "phone cannot be empty",
          trigger: "blur",
        },
      ],
      birth: [
        { required: true, message: "birth cannot be empty", trigger: "blur" },
      ],
    });
    let ruleForm = reactive({
      lastName: "",
      firstName: "",
      nickname: "",
      sex: "0",
      password: "",
      password2: "",
      payCode: "",
      payCode2: "",
      phone: "",
      newsLetter: true,
      policy: true,
      birth: "",
    });
    const ruleFormRef = ref(null);
    const btnState = inject("btnState");
    const userData = computed(() => store.state.user.userData);
    const handleClose = () => {
      emit("handleClose");
    };
    const handleClick = (v) => {
      emit("handleClick", v);
    };
    setTimeout(() => {
      if (userData.value) {
        ruleForm.firstName = userData.value.firstName;
        ruleForm.lastName = userData.value.lastName;
        ruleForm.nickname = userData.value.nickName;
        ruleForm.sex = userData.value.sex;
        if (userData.value.birth) {
          let from = userData.value.birth.split("/");
          ruleForm.birth = new Date(from[2], from[1] - 1, from[0]);
        }
      }
    }, 100);

    const timeChange = (v) => {
      let birthdays = new Date(v);
      let d = new Date();
      let differYear =
        d.getFullYear() -
        birthdays.getFullYear() -
        (d.getMonth() < birthdays.getMonth() ||
        (d.getMonth() == birthdays.getMonth() &&
          d.getDate() < birthdays.getDate())
          ? 1
          : 0);
      if (differYear < 18) {
        store.dispatch("http/setHttpState", {
          msg: "User under the age of 18 are not eligible to use our Service.",
          dialogVisible: true,
        });
        ruleForm.birth = "";
      }
    };

    const onSubmit = async () => {
      if (!ruleForm.newsLetter) {
        store.dispatch("http/setHttpState", {
          msg: "News letter cannot be empty",
          dialogVisible: true,
        });
        return;
      }
      if (!ruleForm.policy) {
        store.dispatch("http/setHttpState", {
          msg: "policy cannot be empty",
          dialogVisible: true,
        });
        return;
      }
      // 检验密码是否一致
      if (ruleForm.password !== ruleForm.password2) {
        store.dispatch("http/setHttpState", {
          msg: "password need be the same",
          dialogVisible: true,
        });
        return;
      }
      // 检验支付密码是否一致
      if (ruleForm.payCode !== ruleForm.payCode2) {
        store.dispatch("http/setHttpState", {
          msg: "pay code need be the same",
          dialogVisible: true,
        });
        return;
      }
      // 验证密码跟支付是否一致
      if (ruleForm.payCode == ruleForm.password) {
        store.dispatch("http/setHttpState", {
          msg: "payment pin cannot same to the password",
          dialogVisible: true,
        });
        return;
      }
      await ruleFormRef.value.validate((valid) => {
        if (valid) {
          emit("handleSubmit", ruleForm);
        }
      });
    };

    const handleBack = () => {
      emit("handleBack");
    };
    return {
      ruleForm,
      rules,
      btnState,
      ruleFormRef,
      timeChange,
      handleClose,
      onSubmit,
      handleClick,
      handleBack,
    };
  },
};
</script>

<style scoped lang="scss">
.step1 {
  width: 760px;
  text-align: left;
  color: #ffffff;
  margin: 0 auto;

  p:first-child {
    font-size: 38px;
    font-weight: bold;
    padding-bottom: 30px;
  }

  :deep(.form) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .label-name:before {
      content: "*";
      color: red;
      padding-right: 6px;
    }
    .label-name {
      padding-bottom: 6px;
      font-size: 16px;
    }
    .el-form-item {
      margin-right: 0;
      margin-bottom: 6px;
    }

    .el-form-item__content {
      display: block;
      padding-top: 10px;
    }

    .el-form-item__error {
      font-size: 16px;
    }

    .el-button {
      width: 216px;
      height: 55px;
      border-radius: 5px;
      margin-top: 44px;
      font-size: 20px;
    }

    .el-input {
      border: none;
      height: 50px;
      width: 370px;

      .el-input__wrapper {
        width: 460px;
        height: 50px;
        background: #272c38;
        border-radius: 6px;
        border: none;

        .el-input__inner {
          width: 100%;
          height: 100%;
          line-height: 50px;
          font-size: 18px;
          color: #ffffff;
        }
      }

      .el-input-group__append {
        margin-left: 20px;
        border-radius: 8px;
        padding: 0;

        .verify {
          padding: 0 36px;
          cursor: pointer;
        }
      }
    }
  }

  :deep(.el-checkbox) {
    .el-checkbox__label {
      color: #ffffff;
    }
  }

  :deep(.btn) {
    text-align: center;

    .el-button {
      width: 216px;
      height: 55px;
      border-radius: 5px;
      margin-top: 44px;
      font-size: 20px;
    }

    .el-form-item__content {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
  }
}
</style>
