import axios from "axios"
import { ElMessage } from 'element-plus'
import store from "@/store";

// export const baseUrl = 'http://1.14.75.127:8082'
// export const baseUrl = window.location.origin+'/proxy/'
// export const baseUrl = 'http://162.14.123.184:8081'
export const baseUrl = 'https://sidequesthub.com/proxy/'

//创建axios实例
const request = axios.create({
    baseURL: baseUrl,
    // baseURL: window.location.origin+'/proxy/',
    timeout: 10000,
})

//请求拦截
request.interceptors.request.use((config:any)=>{
    config.headers = {
        'X-Wanyoo-Token':`${window.sessionStorage.getItem('Authorization') || ''}`
    }
    return config
},err=>{
    return Promise.reject(err)
})

//响应拦截
request.interceptors.response.use((res:any)=>{
    if(res.data.code === 200) return  res
    else if(res.data.code === 401){
        sessionStorage.clear()
        if(location.href.indexOf('/h5') > -1){
            ElMessage({
                type:'error',
                message:res.data.msg,
            })
        }else {
            store.dispatch('http/setLoginState',true)
        }
    }
    else if(res.data.code === 402){
        sessionStorage.clear()
        if(location.href.indexOf('/h5') == -1){
            window.location.href = res.data.url
        }
    } else if(res.data.code !== 200 && location.href.indexOf('/h5') > -1) {
        ElMessage({
            type:'error',
            message:res.data.msg
        })

        return Promise.reject(res)
    }else {
        store.dispatch('http/setHttpState',{
            state:false,
            dialogVisible:true,
            msg:res.data.msg
        })
    }
    return Promise.reject(res)
},err=>{
    return Promise.reject(err)
})


/**
 * 用于发送请求的通用方法
 * @param {*} data 配置信息，method请求方法，params参数，path路由
 * @returns
 */
async function http(data:any){
    let result
    if(data.method === 'get' || data.method === 'delete'){
        await request({
            url: data.path,
            method: data.method,
            params:data.params
        }).then(res=>{
            result = res.data.data?res.data.data:res.data
        })
            .catch(() => {
                console.log('err')
            })
    }else if(data.method === 'post' || data.method === 'put' || data.method === 'patch'){
        await request({
            url: data.path,
            method: data.method,
            data:data.params
        }).then(res=>{
            result = res.data.data?res.data.data:res.data
        })
            .catch(() => {
                console.log('err')
            })
    }

    return result

}

export default http
