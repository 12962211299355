import * as types from './mutation_types'
import http from "@/utils/request";

export const getFaqList = (store:any) => {
    store.commit(types.FAQ_LIST, null)
    return http({path: '/web/issues/top', method: 'get'}).then(res => {
        store.commit(types.FAQ_LIST, res)
        return res
    })
}
export const getFaqValList = (store:any,val:string) => {
    store.commit(types.FAQ_LIST, null)
    return http({path: '/web/issues/list/', method: 'get',params:val}).then(res => {
        store.commit(types.FAQ_LIST, res)
        return res
    })
}
