<template>
  <div class="header transition" :class="{ scroll: className }">
    <div class="logo">
      <router-link v-if="navShow" to="/home">
        <img :src="require('../../assets/logo-yellow.png')" alt="Home" />
      </router-link>
      <router-link v-else>
        <img :src="require('../../assets/logo-yellow.png')" alt="SideQuest" />
      </router-link>
    </div>
    <div class="tab" v-if="navShow">
      <el-menu
        :default-active="activeIndex"
        class="overflow-hidden"
        mode="horizontal"
        :ellipsis="false"
      >
        <el-menu-item index="/faqList" @click="router.push('/faqList')">
          <router-link to="/faqList">How it works</router-link>
        </el-menu-item>
        <el-menu-item index="/games" @click="router.push('/games')">
          <router-link to="/games">Games</router-link>
        </el-menu-item>
        <el-menu-item index="/tournaments" @click="router.push('/tournaments')">
          <router-link to="/tournaments">Events</router-link>
        </el-menu-item>
        <el-menu-item index="/news" @click="router.push('/news')">
          <router-link to="/news">News</router-link>
        </el-menu-item>
        <el-menu-item index="/membership" @click="router.push('/membership')">
          <router-link to="/membership">Membership</router-link>
        </el-menu-item>
        <el-menu-item index="/about" @click="router.push('/about')">
          <router-link to="/about">About Us</router-link>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="header-user">
      <div v-if="userData == null" class="head-img-b" @click="handleLogin">
        <img :src="require('@/assets/default.png')" alt="" />
      </div>
      <div v-else>
        <el-popover
          placement="bottom"
          trigger="click"
          popper-class="user-pop"
          v-model:visible="popperShow"
        >
          <nav class="user-table">
            <p class="title">User Settings</p>
            <el-menu class="el-menu-vertical-demo">
              <router-link to="/user?type=first">
                <el-menu-item index="4">
                  <span>My Account</span>
                </el-menu-item>
              </router-link>
              <router-link to="/user?type=second">
                <el-menu-item index="1">
                  <span>Join Game</span>
                </el-menu-item>
              </router-link>
              <!--              <router-link to="/user?type=third">-->
              <!--                <el-menu-item index="2">-->
              <!--                  <span>My Order</span>-->
              <!--                </el-menu-item>-->
              <!--              </router-link>-->
              <router-link to="/user?type=fourth">
                <el-menu-item index="3">
                  <span>Address</span>
                </el-menu-item>
              </router-link>
              <router-link to="/user?type=five">
                <el-menu-item index="5">
                  <span>Vouchers</span>
                </el-menu-item>
              </router-link>
              <router-link to="/user?type=six">
                <el-menu-item index="6">
                  <span>Booking</span>
                </el-menu-item>
              </router-link>
              <router-link to="/user?type=seven">
                <el-menu-item index="7">
                  <span>Game Account</span>
                </el-menu-item>
              </router-link>
<!--                           <router-link to="/integral">-->
<!--                             <el-menu-item index="7">-->
<!--                               <span>Integral</span>-->
<!--                             </el-menu-item>-->
<!--                           </router-link>-->
              <router-link to="/user-setting">
                <el-menu-item index="6">
                  <span>Setting</span>
                </el-menu-item>
              </router-link>
            </el-menu>

            <div class="btn" @click="handleOUt">LOG OUT</div>
          </nav>
          <template #reference>
            <div class="head-img">
              <img :src="userData.memberPhoto" alt="" />
              <p>{{ userData.nickName }}!</p>
            </div>
          </template>
        </el-popover>
      </div>
    </div>
  </div>
  <div class="mobile-header">
    <MobileHeader @handleLogin="handleLogin" />
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { computed, inject, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import MobileHeader from "@/components/web-header-footer/mobile-header";

export default {
  name: "header-web",
  components: { MobileHeader },
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const activeIndex = ref("/" + router.currentRoute.value.meta.name);
    const userData = computed(() => store.state.user.userData);
    const cartData = computed(() => store.state.shop.cartData);
    const storeList = computed(() => store.state.store.storeList);
    const className = ref(false);
    const popperShow = ref(false);
    const navShow = ref(true);
    const userState = inject("userState");
    store.dispatch("store/getStoreList");

    onMounted(() => {
      window.addEventListener("scroll", scrollTop, true);
    });
    const clientWidth = computed(
      () => document.documentElement.clientWidth < 1300
    );
    const scrollTop = () => {
      let scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      className.value = scroll > 200;
    };
    watch(
      router.currentRoute,
      (v) => {
        popperShow.value = false;
        if (v.meta.name === "store") activeIndex.value = v.fullPath;
        else activeIndex.value = "/" + v.meta.name;
        navShow.value = v.meta.name !== "shpw";
      },
      { immediate: true }
    );
    const handleLogin = () => {
      emit("handleLogin");
    };
    const handleOUt = () => {
      sessionStorage.clear();
      window.location.href = "/";
    };
    const handleLink = () => {
      if (userState) {
        router.push("/cart");
        store.dispatch("shop/setCartStep", 0);
      } else {
        store.dispatch("http/setLoginState", true);
      }
    };
    const handleClick = (v) => {
      console.log(v);
    };

    return {
      router,
      userData,
      activeIndex,
      cartData,
      storeList,
      className,
      navShow,
      popperShow,
      handleLogin,
      handleOUt,
      handleLink,
      handleClick,
    };
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/base" as *;

.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  height: 120px;
  align-items: center;
  width: 100vw;
  z-index: 100;
  padding: 0 1.5rem;
  backdrop-filter: blur(5px);
  mask-image: linear-gradient(to bottom, black 70%, transparent 100%);

  @include media("<=960px") {
    display: none;
  }

  .logo {
    flex: 0 0 fit-content;

    img {
      width: 200px;
      margin-left: 20px;
    }
  }

  :deep(.tab) {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 20px;

    .el-menu {
      background: none;
      font-weight: bold;

      .el-menu-item,
      .el-sub-menu__title {
        color: #ffffff;
        background: none;
      }

      .el-menu-item {
        padding: 0 20px;

        a {
          color: #ffffff;
          @include text-sm;
          white-space: wrap;
        }

        &:hover {
          border-color: var(--el-color-primary-hover);
        }

        @include media("<desktop") {
          &:first-child {
            padding: 0 20px 0 0;
          }
          &:last-child {
            padding: 0 0 0 20px;
          }
        }
      }
    }
  }

  .head-img {
    color: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
      border: 1px solid #ffb20e;
    }

    p {
      font-size: 14px;
      max-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .head-img-b {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #bbbbbb;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #ffb20e;

    img {
      width: 100%;
    }
  }

  :deep(.header-user) {
    flex: 0 0 fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .el-badge {
      width: 40px;
      margin-right: 40px;
      cursor: pointer;

      img {
        width: 25px;
        background-color: #252932;
        padding: 10px;
        border-radius: 10px;
        border-style: solid;
        border-color: #ffb20e;
        border-width: 1px;
      }

      .el-badge__content {
        right: 0;
      }

      img:hover {
        background-color: #bbbbbb;
      }
    }
  }
}

.mobile-header {
  @include media(">960px") {
    display: none;
  }
}
</style>
