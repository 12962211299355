import { createApp } from "vue";
import { createHead } from "@unhead/vue";
import { SchemaOrgUnheadPlugin } from "@vueuse/schema-org";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant, { Lazyload } from "vant";
import { register } from "swiper/element/bundle";
import "vant/lib/index.css";
import ScrollParallax from "vue3-parallax/src/components/ScrollParallax.vue";
import { GesturePlugin } from "@vueuse/gesture";
import { MotionPlugin } from "@vueuse/motion";
import ElementPlus from "element-plus";
import "./assets/css/index.scss";
import "./assets/css/element.scss";
import "./assets/css/swiper.scss";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import HttpState from "@/components/web/http/http.vue";
import "@/utils/rem";

const app = createApp(App);
const head = createHead();

register();

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.config.compilerOptions.isCustomElement = (tag) =>
  tag === "marquee" || tag === "swiper-container" || tag === "swiper-slide";

app.component("HttpState", HttpState);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// app.use(vConsole)
// app.use(flexible)

app
  .use(store)
  .use(router)
  .use(Vant)
  .use(ElementPlus)
  .use(GesturePlugin)
  .use(MotionPlugin)
  .use(Lazyload)
  .use(head)
  .component("scroll-parallax", ScrollParallax);

head.use(
  SchemaOrgUnheadPlugin(
    {
      host: "https://sidequesthub.com",
    },
    () => {
      // route meta
      return {};
    },
  ),
);

app.mount("#app");
