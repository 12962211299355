import * as types from "./mutation_types"

export default {
    [types.USER_DATA] (state:any,data:any) {
        if(data){
            state.userData = data.user
            sessionStorage.setItem('userData',JSON.stringify(data))
        }
    },
    [types.MEMBER_LIST] (state:any,data:any) {
        state.memberList = data
    },
    [types.ADDRESS_LIST] (state:any,data:any) {
        state.addressList = data
    },
    [types.ENROLL_LIST] (state:any,data:any) {
        state.enrollList = data
    },
    [types.ORDER_LIST] (state:any,data:any) {
        console.log(data)
        state.orderList = data
    },
    [types.CONSUME_LIST] (state:any,data:any) {
        state.consumeList = data
    },
    [types.COUPON_LIST] (state:any,data:any) {
        state.couponList = data
    },
    [types.BOOKING_LIST] (state:any,data:any) {
        state.bookingList = data
    },
    [types.LOGIN_STATUS] (state:any,data:any) {
        state.loginStatus = data
    }
}
