import * as actions from './actions'
import mutations from './mutations'
export default {
    namespaced: true,
    state:{
        userData: null,
        memberList:null,
        addressList:null,
        enrollList:null,
        orderList:null,
        consumeList:null,
        couponList:null,
        bookingList:null,
        loginStatus: false,
    },
    actions,
    mutations
}
