import * as actions from "./actions";
import mutations from "./mutations";

export default {
	namespaced: true,
	state: {
		customerList: [],
		conversationID: "",
		messageList: [],
		systemMessageCount: null,
		groupList: [],
		groupMemberList: [],
	},
	actions,
	mutations,
};
