import * as actions from './actions'
import mutations from './mutations'

export default {
    namespaced: true,
    state: {
        shpwData:null,
        preOrderData:null,
    },
    actions,
    mutations
}