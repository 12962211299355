<template>
  <div class="forget">
    <p class="header">Find Your password</p>

    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" class="form">
      <el-form-item prop="email">
        <el-input
          v-model="ruleForm.email"
          autocomplete="new-password"
          placeholder="Enter your email"
        >
          <template #append>
            <div class="verify" @click="handleVerify">
              {{ num ? num : "Verify" }}
            </div>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="verifyCode">
        <el-input
          v-model="ruleForm.verifyCode"
          auto-complete="off"
          placeholder="Enter your verification code"
        />
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="ruleForm.password"
          type="password"
          autocomplete="new-password"
          placeholder="Password"
        />
        <p class="password-hint">
          *At least 6 characters long, containing uppercase and lowercase
          letters and numbers.
        </p>
      </el-form-item>
      <el-form-item class="btn">
        <el-button type="primary" @click="handleBack">Back</el-button>
        <el-button type="primary" class="submit" @click="onSubmit"
          >Submit</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "index-login",
  setup(props, { emit }) {
    const store = useStore();
    const ruleFormRef = ref(null);
    const rules = reactive({
      email: [{ required: true, message: "email is required" }],
      verifyCode: [
        { required: true, message: "verification code is required" },
      ],
      password: [{ required: true, message: "password is required" }],
    });

    const ruleForm = reactive({
      email: "",
      verifyCode: "",
      password: "",
      uid: "",
    });

    /**
     * 获取邮箱验证码
     */
    const num = ref(0);
    const handleVerify = () => {
      if (num.value) {
        return;
      }
      let pattern =
        /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/.test(
          ruleForm.email,
        );
      if (ruleForm.email === "" || !pattern) {
        store.dispatch("http/setHttpState", {
          msg: "email must have a valid value",
          dialogVisible: true,
        });
        ruleForm.email = "";
        return;
      }

      store
        .dispatch("user/getEmailCode", { email: ruleForm.email })
        .then((res) => {
          if (res) {
            num.value = 60;
            ruleForm.uid = res.uid;
            let time = setInterval(function () {
              num.value--;
              if (num.value === 0) {
                clearInterval(time);
                num.value = undefined;
              }
            }, 1000);
          }
        });
    };

    /**
     * 修改密码提交
     */
    const onSubmit = async () => {
      let pattern =
        /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/.test(
          ruleForm.email,
        );
      if (ruleForm.email === "" || !pattern) {
        store.dispatch("http/setHttpState", {
          msg: "Email must have a valid value",
          dialogVisible: true,
        });
        ruleForm.email = "";
        return;
      }
      await ruleFormRef.value.validate((valid) => {
        if (valid) {
          store.dispatch("user/resetPassword", ruleForm).then((res) => {
            if (res) {
              store.dispatch("http/setHttpState", {
                msg: "Password reset successfully!",
                dialogVisible: true,
                state: true,
              });
              emit("handleClick", "0");
            }
          });
        }
      });
    };

    const handleBack = () => {
      emit("handleClick", "0");
    };
    return {
      ruleForm,
      rules,
      num,
      onSubmit,
      ruleFormRef,
      handleVerify,
      handleBack,
    };
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/base" as *;

.forget {
  width: 100%;
  max-width: 460px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  color: #ffffff;
  padding: 0 24px;

  .header {
    @include text-shadow;
    @include text-4xl;
    @include font-bold;
    padding-bottom: 30px;
  }

  :deep(.form) {
    .el-form-item__error {
      @include text-base;
    }

    .el-input {
      border: none;
      padding-top: 20px;
      display: flex;
      gap: 16px;

      .el-input__wrapper {
        height: 55px;
        background: #272c38;
        border-radius: 12px;
        border: none;

        .el-input__inner {
          @include text-lg;
          width: 100%;
          height: 100%;
          color: #ffffff;
          background-color: #272c38;
        }
      }

      .el-input-group__append {
        padding: 0;
        background-color: transparent;
        box-shadow: unset;

        .verify {
          @include button-base;
          width: 72px;
          height: 100%;
          cursor: pointer;
        }
      }
    }

    .password-hint {
      @include text-shadow;
      @include text-sm;
      padding: 8px 12px 0;
      line-height: 20px;
    }

    .btn {
      background: none;
      color: #ffffff;

      .el-form-item__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
      }

      .el-button {
        @include button-base;
        @include text-xl;
        width: 216px;
        height: 55px;

        &.submit {
          color: white;
        }
      }
    }
  }
}
</style>
