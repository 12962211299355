// 从v2.11.2起，SDK 支持了 WebSocket，推荐接入；v2.10.2及以下版本，使用 HTTP
// v2.24.0起，SDK 支持使用本地审核插件
import TIM from "tim-js-sdk";
import TIMUploadPlugin from "tim-upload-plugin";
import LibGenerateTestUserSig from "./lib-generate-test-usersig.min.js";
import store from "@/store";
import {ElMessage} from "element-plus";

function genTestUserSig(userID) {
	const generator = new LibGenerateTestUserSig(40000072, "d86fe99cef3a9aef6e5361cad5daedcb20130c7336606e957c3d6e0bb1afc76a", 172800);
	const userSig = generator.genTestUserSig(userID);
	return {
		userID,
		userSig,
	};
}

let options = {
	SDKAppID: 40000072, // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
	secretKey: "d86fe99cef3a9aef6e5361cad5daedcb20130c7336606e957c3d6e0bb1afc76a",
};
// 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
let tim = TIM.create(options); // SDK 实例通常用 tim 表示

function createTim() {
	// 设置 SDK 日志输出级别，详细分级请参见 setLogLevel https://web.sdk.qcloud.com/im/doc/zh-cn/SDK.html#setLogLevel 接口的说明</a>
	tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
	// tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用

	// 注册腾讯云即时通信 IM 上传插件
	tim.registerPlugin({ "tim-upload-plugin": TIMUploadPlugin });

	// SDK 进入 ready 状态时触发，接入侧监听此事件，然后可调用 SDK 发送消息等 API，使用 SDK 的各项功能
	let onSdkReady = function () {
		// let message = tim.createTextMessage({to: 'user1', conversationType: 'C2C', payload: {text: 'Hello world!'}});
		// tim.sendMessage(message);
	};
	tim.on(TIM.EVENT.SDK_READY, onSdkReady);

	// SDK 进入 not ready 状态时触发，此时接入侧将无法使用 SDK 发送消息等功能。如果想恢复使用，接入侧需调用 login 接口，驱动 SDK 进入 ready 状态
	let onSdkNotReady = function () {
		// 如果想使用发送消息等功能，接入侧需驱动 SDK 进入 ready 状态，重新调用 login 接口即可，如下所示：
		// tim.login({userID: 'your userID', userSig: 'your userSig'});
	};
	tim.on(TIM.EVENT.SDK_NOT_READY, onSdkNotReady);

	// SDK 收到推送的单聊、群聊、群提示、群系统通知的新消息，接入侧可通过遍历 event.data 获取消息列表数据并渲染到页面
	function message_received(event) {
		event.data.forEach((message) => {
			console.log(message);
			if (message.type === "TIMCustomElem") {
				let messageObj = eval("(" + message.payload.data + ")");
				if (messageObj.type === "play_order" && message.conversationID.indexOf(message.from) !== -1) {
					messageObj.avatar = message.avatar;
					messageObj.name = message.nick;
					messageObj.uid = message.from;
					store.dispatch("user/setMessageStatus", messageObj);
				}
				if (message.type === "match_order_player") {
					console.log(messageObj);
				}
			}
			if (message.conversationID === store.state.tim.conversationID) {
				store.dispatch("tim/setMessageList", [message]);
				tim.setMessageRead({ conversationID: store.state.tim.conversationID });
			}
		});
		// let totalUnreadMessageCount = tim.getTotalUnreadMessageCount();
		// if (0 !== totalUnreadMessageCount) {
		// 	// document.getElementById("customer-iframe").attr("src","@/assets/sounds/new_message.mp3");
		// 	document.getElementById("customer-iframe").src = "@/assets/sounds/new_message.mp3";
		// }
		setTimeout(function () {
			let messageui = document.getElementsByClassName("customer-scrollbar-right")[0];
			if (messageui === undefined || messageui === null) {
				return;
			}
			messageui.scrollTop = messageui.scrollHeight - messageui.clientHeight + 20;
		}, 100);
	}

	tim.on(TIM.EVENT.MESSAGE_RECEIVED, message_received);

	let onTotalUnreadMessageCountUpdated = function (event) {
		let count = "0";
		if (0 !== event.data) {
			count = `${event.data}`;
		}
		document.getElementById("tim-total-message-count").innerHTML = count;
	};
	tim.on(TIM.EVENT.TOTAL_UNREAD_MESSAGE_COUNT_UPDATED, onTotalUnreadMessageCountUpdated);

	// 会话列表更新，event.data 是包含 Conversation 对象的数组
	let onConversationListUpdated = function (event) {
		// if (tim.read)
		let customerList = [];
		let groupList = store.state.tim.groupList;
		event.data.forEach(function (data) {
			if (data.type === "GROUP") {
				groupList.forEach((item) => {
					if (item.uid === data.groupProfile.groupID) {
						item.unreadCount = data.unreadCount;
					}
				});
			} else if (data.type === "C2C" && data.userProfile.userID !== "sq_hide_notify") {
				if (data.userProfile.userID !== "system") {
					customerList.push({
						uid: data.userProfile.userID,
						name: data.userProfile.nick,
						avatar: data.userProfile.avatar,
						conversationID: data.conversationID,
						unreadCount: data.unreadCount,
						lastMessage: data.lastMessage,
					});
				} else {
					customerList.push({
						uid: data.userProfile.userID,
						name: data.userProfile.nick,
						avatar: data.userProfile.avatar,
						conversationID: data.conversationID,
						unreadCount: data.unreadCount,
						lastMessage: data.lastMessage,
					});
					if (data.unreadCount > 0) {
						store.dispatch("tim/setSystemMessageCount", data);
					}
				}
			}
		});
		sessionStorage.setItem("timMessageList", JSON.stringify(customerList));
		store.dispatch("tim/setCustomerList", customerList);
		store.dispatch("tim/setGroupList", groupList);
	};
	tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, onConversationListUpdated);

	// 用户被踢下线时触发
	let onKickedOut = function (event) {
		// TIM.TYPES.KICKED_OUT_MULT_ACCOUNT(Web端，同一帐号，多页面登录被踢)
		// TIM.TYPES.KICKED_OUT_MULT_DEVICE(同一帐号，多端登录被踢)
		// TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED(签名过期)
		// TIM.TYPES.KICKED_OUT_REST_API(REST API kick 接口踢出。v2.20.0起支持)
		sessionStorage.clear();
		store.dispatch("user/warningPopup");
		// ElMessage({
		// 	message: "TIM Logout, Please Reload this page!",
		// 	type: "warning",
		// });
	};
	tim.on(TIM.EVENT.KICKED_OUT, onKickedOut);

	// 网络状态发生改变
	let onNetStateChange = function () {
		// v2.5.0 起支持
		// event.data.state 当前网络状态，枚举值及说明如下：
		// TIM.TYPES.NET_STATE_CONNECTED - 已接入网络
		// TIM.TYPES.NET_STATE_CONNECTING - 连接中。很可能遇到网络抖动，SDK 在重试。接入侧可根据此状态提示“当前网络不稳定”或“连接中”
		// TIM.TYPES.NET_STATE_DISCONNECTED - 未接入网络。接入侧可根据此状态提示“当前网络不可用”。SDK 仍会继续重试，若用户网络恢复，SDK 会自动同步消息
	};
	tim.on(TIM.EVENT.NET_STATE_CHANGE, onNetStateChange);
}

export function loginTim(member_code) {
	createTim();
	let userSig = genTestUserSig(member_code);
	return tim.login(userSig);
}

export function getTimUserData() {
	return new Promise((resolve, reject) => {
		let promise = tim.getMyProfile();
		console.log("tim---------", tim);
		promise
			.then(function (imResponse) {
				console.log(imResponse.data); // 个人资料 - Profile 实例
				resolve(imResponse.data);
			})
			.catch(function (imError) {
				console.warn("getMyProfile error:", imError); // 获取个人资料失败的相关信息
				reject(imError);
			});
	});
}

// function logoutTim() {
//   let promise = tim.logout();
//   promise.then(function (imResponse) {
//     console.log(imResponse.data); // 登出成功
//   }).catch(function (imError) {
//     console.warn('logout error:', imError);
//   });
// }
//
// function closeTim() {
//   tim.destroy().then(() => {
//     console.log('sdk destroyed');
//   });
// }

export function tim_sendMessage(user, text, type) {
	console.log(user)
	if (text === null || text.length === 0) {
		return;
	}
	// 发送文本消息，Web 端与小程序端相同
	// 1. 创建消息实例，接口返回的实例可以上屏
	let message = tim.createTextMessage({
		to: user,
		conversationType: type === "3" ? TIM.TYPES.CONV_GROUP : TIM.TYPES.CONV_C2C,
		// 消息优先级，用于群聊（v2.4.2起支持）。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息，
		// 详细请参考：https://cloud.tencent.com/document/product/269/3663#.E6.B6.88.E6.81.AF.E4.BC.98.E5.85.88.E7.BA.A7.E4.B8.8E.E9.A2.91.E7.8E.87.E6.8E.A7.E5.88.B6)
		// 支持的枚举值：TIM.TYPES.MSG_PRIORITY_HIGH, TIM.TYPES.MSG_PRIORITY_NORMAL（默认）, TIM.TYPES.MSG_PRIORITY_LOW, TIM.TYPES.MSG_PRIORITY_LOWEST
		// priority: TIM.TYPES.MSG_PRIORITY_NORMAL,
		payload: {
			text: text,
		},
		// v2.20.0起支持C2C消息已读回执功能，如果您发消息需要已读回执，需购买旗舰版套餐，并且创建消息时将 needReadReceipt 设置为 true
		needReadReceipt: true,
		// 消息自定义数据（云端保存，会发送到对端，程序卸载重装后还能拉取到，v2.10.2起支持）
		// cloudCustomData: 'your cloud custom data'
	});
	// 2. 发送消息
	let promise = tim.sendMessage(message);
	promise
		.then(function (imResponse) {
			// 发送成功
			store.dispatch("tim/setMessageList", [imResponse.data.message]);
			setTimeout(function () {
				let messageui = document.getElementsByClassName("customer-scrollbar-right")[0];
				if (messageui === undefined || messageui === null) {
					return;
				}
				messageui.scrollTop = messageui.scrollHeight - messageui.clientHeight;
			}, 100);
		})
		.catch(function (imError) {
			// 发送失败
			console.warn("sendMessage error:", imError);
		});
}

export function getGroupList() {
	let promise = tim.getGroupList();
	promise
		.then(function (imResponse) {
			console.log(imResponse);
			store.dispatch("tim/setGroupList", []);
			let groupList = [];
			imResponse.data.groupList.forEach(function (data) {
				groupList.push({
					uid: data.groupID,
					name: data.name,
					avatar: data.avatar,
					conversationID: "1",
					unreadCount: data.unreadCount,
					lastMessage: data.lastMessage,
				});
			});
			store.dispatch("tim/setGroupList", groupList);
		})
		.catch(function (imError) {
			console.warn("getGroupList error:", imError); // 获取群组列表失败的相关信息
		});
}

export function getConversationList() {
	let promise = tim.getConversationList();
	promise
		.then(function (imResponse) {
			console.log(imResponse);
			// store.dispatch("tim/setCustomerList", []);
			store.dispatch("tim/setGroupList", []);
			let customerList = [];
			let groupList = [];
			imResponse.data.conversationList.forEach(function (data) {
				if (data.type === "GROUP") {
					groupList.push({
						uid: data.groupProfile.groupID,
						name: data.groupProfile.name,
						avatar: data.groupProfile.avatar,
						conversationID: data.conversationID,
						unreadCount: data.unreadCount,
						lastMessage: data.lastMessage,
					});
				} else if (data.type === "C2C" && data.userProfile.userID !== "sq_hide_notify") {
					customerList.push({
						uid: data.userProfile.userID,
						name: data.userProfile.nick,
						avatar: data.userProfile.avatar,
						conversationID: data.conversationID,
						unreadCount: data.unreadCount,
						lastMessage: data.lastMessage,
					});
					let unreadCount = 0;
					if (data.userProfile.userID === "system" || data.userProfile.userID === "Order Message" || data.userProfile.userID === "Social Message") {
						if (data.unreadCount > 0) {
							unreadCount += data.unreadCount;
						}
					}
					store.dispatch("tim/setSystemMessageCount", unreadCount);
				}
			});
			sessionStorage.setItem("timMessageList", JSON.stringify(customerList));
			store.dispatch("tim/setCustomerList", customerList);
			store.dispatch("tim/setGroupList", groupList);
		})
		.catch(function (imError) {
			console.warn("getConversationList error:", imError); // 获取会话列表失败的相关信息
		});
}

export function getMessageList(nextReqMessageID) {
	let messageui = document.getElementsByClassName("customer-scrollbar-right")[0];
	if (messageui === undefined || messageui === null) {
		return;
	}
	let thisScrollTop = messageui.scrollTop;
	let thisScrollHeight = messageui.scrollHeight;
	// 下拉查看更多消息
	let promise;
	if (nextReqMessageID[0] !== undefined) {
		promise = tim.getMessageList({
			conversationID: store.state.tim.conversationID,
			nextReqMessageID: nextReqMessageID[0],
		});
	} else {
		store.dispatch("tim/setMessageList", null);
		thisScrollTop = undefined;
		promise = tim.getMessageList({ conversationID: store.state.tim.conversationID });
	}
	promise.then(function (imResponse) {
		let allmessage;
		if (store.state.tim.messageList.length !== 0) {
			allmessage = imResponse.data.messageList.concat(store.state.tim.messageList);
			store.dispatch("tim/setMessageList", null);
		} else {
			allmessage = imResponse.data.messageList;
		}

		console.log('--------------------',allmessage);
		store.dispatch("tim/setMessageList", allmessage);
		nextReqMessageID[0] = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
		const isCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。
		if (isCompleted) {
			nextReqMessageID[0] = undefined;
		}

		setTimeout(function () {
			let messageui = document.getElementsByClassName("customer-scrollbar-right")[0];
			if (messageui === undefined || messageui === null) {
				return;
			}
			if (thisScrollTop === undefined) {
				messageui.scrollTop = messageui.scrollHeight - messageui.clientHeight;
			} else {
				messageui.scrollTop = thisScrollTop + messageui.scrollHeight - thisScrollHeight;
			}
		}, 100);
	});
	promise = tim.setMessageRead({ conversationID: store.state.tim.conversationID });
	promise
		.then(function () {
			// 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
		})
		.catch(function (imError) {
			// 已读上报失败
			console.warn("setMessageRead error:", imError);
		});
}

export function getUserPorfile(userId) {
	return new Promise((resolve, reject) => {
		let promise = tim.getUserProfile({
			userIDList: [userId], // 请注意：即使只拉取一个用户的资料，也需要用数组类型，例如：userIDList: ['user1']
		});
		promise
			.then(function (imResponse) {
				resolve(imResponse.data);
			})
			.catch(function (imError) {
				console.warn("getUserProfile error:", imError); // 获取其他用户资料失败的相关信息
			});
	});
}

export function createGroup(name, introduction) {
	// 创建好友工作群
	let promise = tim.createGroup({
		type: TIM.TYPES.GRP_MEETING,
		name: name,
		introduction: introduction,
	});
	promise
		.then(function (imResponse) {
			// 创建成功
			console.log(imResponse.data.group); // 创建的群的资料
			// 创建群时指定了成员列表，但是成员中存在超过了“单个用户可加入群组数”限制的情况
			// 一个用户 userX 最多允许加入 N 个群，如果已经加入了 N 个群，此时创建群再指定 userX 为群成员，则 userX 不能正常加群
			// SDK 将 userX 的信息放入 overLimitUserIDList，供接入侧处理
			console.log(imResponse.data.overLimitUserIDList); // 超过了“单个用户可加入群组数”限制的用户列表，v2.10.2起支持
			return true;
		})
		.catch(function (imError) {
			console.warn("createGroup error:", imError); // 创建群组失败的相关信息
		});
	return true;
}

export function joinGroup(groupID) {
	let promise = tim.joinGroup({ groupID: groupID, type: TIM.TYPES.GRP_MEETING });
	return promise
		.then(function (imResponse) {
			let msg = 1;
			switch (imResponse.data.status) {
				case TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 等待管理员同意
					msg = 1;
					break;
				case TIM.TYPES.JOIN_STATUS_SUCCESS: // 加群成功
					console.log(imResponse.data.group); // 加入的群组资料
					msg = 2;
					break;
				case TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 已经在群中
					msg = 1;
					break;
				default:
					break;
			}

			return msg;
		})
		.catch(function (imError) {
			console.warn("joinGroup error:", imError); // 申请加群失败的相关信息
		});
}

export function getGroupProfile(groupId){
	return tim.getGroupProfile({ groupID: groupId });
}

export function getGroupMemberList(groupID, count = 100, offset = 0) {
	return tim.getGroupMemberList({
		groupID: groupID,
		count: count,
		offset: offset,
	})
}

export function quitGroup(groupID) {
	let promise = tim.quitGroup(groupID);
	promise
		.then(function (imResponse) {
			console.log(imResponse.data.groupID); // 退出成功的群 ID

			ElMessage({
				message: "Success!",
				type: "warning",
			});
			// getConversationList();
			store.dispatch("tim/setMessageList", null);
			getGroupList();
		})
		.catch(function (imError) {
			ElMessage({
				message: imError,
				type: "warning",
			});
			console.warn("quitGroup error:", imError); // 退出群组失败的相关信息
		});
}

export function dismissGroup(groupID) {
	let promise = tim.dismissGroup(groupID);
	promise
		.then(function (imResponse) {
			console.log(imResponse.data.groupID); // 解散成功 ID
			ElMessage({
				message: "success",
				type: "warning",
			});
			// getConversationList();
			store.dispatch("tim/setMessageList", null);
			getGroupList();
		})
		.catch(function (imError) {
			console.warn("dismissGroup error:", imError); // 解散失败的相关信息
			ElMessage({
				message: imError,
				type: "warning",
			});
		});
}

export function changeGroupOwner(groupID, ownerId) {
	let promise = tim.changeGroupOwner({
		groupID: groupID,
		newOwnerID: ownerId,
	});
	return promise
		.then(function (imResponse) {
			console.log(imResponse.data.group); // 转让成功 群组资料
			ElMessage({
				message: "success",
				type: "warning",
			});
		})
		.catch(function (imError) {
			console.warn("changeGroupOwner error:", imError); // 转让群组失败的相关信息
			ElMessage({
				message: imError,
				type: "warning",
			});
		});
}

export function updateGroupProfile(groupID, name = null, introduction = null, notification = null, muteAllMembers = false) {
	let options = { groupID: groupID };
	if (name !== undefined && null !== name && name.length > 0) {
		options.name = name;
	}
	if (introduction !== undefined && null !== introduction && introduction.length > 0) {
		options.introduction = introduction;
	}
	if (notification !== undefined && null !== notification && notification.length > 0) {
		options.notification = notification;
	}
	if (muteAllMembers !== undefined && null !== muteAllMembers) {
		options.muteAllMembers = muteAllMembers;
	}
	let promise = tim.updateGroupProfile(options);
	return promise
		.then(function (imResponse) {
			console.log(imResponse.data.group); // 成功 群组资料
			ElMessage({
				message: "success",
				type: "warning",
			});
		})
		.catch(function (imError) {
			console.warn("updateGroupProfile error:", imError); // 失败的相关信息
			ElMessage({
				message: imError,
				type: "warning",
			});
		});
}

export function handleGroupApplication(handleAction, handleMessage, message) {
	let promise = tim.handleGroupApplication({
		handleAction: handleAction,
		handleMessage: handleMessage,
		message: message,
	});
	return promise
		.then(function (imResponse) {
			console.log(imResponse.data.group); // 成功 群组资料
			ElMessage({
				message: "success",
				type: "warning",
			});
		})
		.catch(function (imError) {
			console.warn("handleGroupApplication error:", imError); // 失败的相关信息
			ElMessage({
				message: imError,
				type: "warning",
			});
		});
}

export function setGroupMemberRole(groupID, userID, role = TIM.TYPES.GRP_MBR_ROLE_ADMIN) {
	let promise = tim.setGroupMemberRole({
		groupID: groupID,
		userID: userID,
		role: role,
	});
	return promise
		.then(function (imResponse) {
			console.log(imResponse.data.memberList); // 群成员列表
			ElMessage({
				message: "success",
				type: "warning",
			});
		})
		.catch(function (imError) {
			console.warn("getGroupMemberList error:", imError); // 失败的相关信息
			ElMessage({
				message: imError,
				type: "warning",
			});
		});
}
