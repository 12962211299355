import * as types from './mutation_types'
import http from "@/utils/request";

export const getGameAll = (store:any) => {
    store.commit(types.GAME_LIST, null)
    http({path: '/web/game/all', method: 'get'}).then(res => {
        store.commit(types.GAME_LIST, res)
    })
}
export const searchGame = (store:any,key:string) => {
    store.commit(types.GAME_LIST, null)
    http({path: `/web/game/search?key=${key}`, method: 'get'}).then(res => {
        store.commit(types.GAME_LIST, res)
    })
}