import * as types from './mutation_types'
import http from "@/utils/request";

export const getShopAll = (store:any) => {
    store.commit(types.SHOP_LIST, null)
    http({path: '/web/shop/index', method: 'get'}).then(res => {
        store.commit(types.SHOP_LIST, res)
    })
}
export const getShopDetail = (store:any,id:string) => {
    store.commit(types.SHOP_DETAIL, null)
    http({path: `/web/shop/goods/${id}`, method: 'get'}).then(res => {
        store.commit(types.SHOP_DETAIL, res)
    })
}
export const getCartList = (store:any) => {
    store.commit(types.CART_LIST, null)
    http({path: `web/member/cart/list`, method: 'get'}).then(res => {
        store.commit(types.CART_LIST, res)
    })
}

export const setCartList = (store:any,data:any) => {
    store.commit(types.CART_LIST, data)
}

export const addCart = (store:any,data:object) => {
    return http({path: `/web/member/cart/add`, method: 'post',params:data}).then(res => {
        return res
    })
}
export const deleteGood = (store:any,id:string) => {
    return http({path: `/web/member/cart/${id}`, method: 'delete'}).then(res => {
        return res
    })
}
export const getCoupon = (store:any,code:string) => {
    return http({path: `/web/member/useCoupon/${code}`, method: 'get'}).then(res => {
        return res
    })
}
export const paymentGoods = (store:any,data:object) => {
    return http({path: `/web/member/order/stripe/add`, method: 'post',params:data}).then(res => {
        return res
    })
}

export const setCartStep = (store:any,data:number) => {
    store.commit(types.CART_STEP, data)
}
export const setOrderData = (store:any,data:any) => {
    store.commit(types.ORDER_DATA, data)
}
