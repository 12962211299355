import * as types from './mutation_types'
import http from "@/utils/request";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {loginTim} from "@/utils/tim.js";

// 保存用户信息
export const setUserData = (store:any,data:any) => {
    store.commit(types.USER_DATA, data)
}

// 保存用户信息
export const getUSerData = (store:any) => {
    store.commit(types.USER_DATA, null)
    http({path: `/web/index/user`, method: 'get'}).then((res:any) => {
        if(res.user && window.location.href.indexOf('source') === -1){
            loginTim(res.user.memberCode)
                .then((res:any) => {
                    store.commit(types.LOGIN_STATUS, true)
                })
        }
        store.commit(types.USER_DATA, res)
    })
}

// 获取会员列表
export const getMemberList = (store:any) => {
    store.commit(types.MEMBER_LIST, null)
    http({path: `/web/index/memberLevel`, method: 'get'}).then(res => {
        store.commit(types.MEMBER_LIST, res)
    })
}

// 会员充值
export const memberUpgrade = (store:any,data:object) => {
    return http({path: `/web/member/charge/stripe/add`, method: 'post', params:data}).then(res => {
        return res
    })
}
// 会员充值
export const shpwUpgrade = (store:any,data:object) => {
    return http({path: `/web/dcpay/stripe/charge`, method: 'post', params:data}).then(res => {
        return res
    })
}
// 会员充值
export const checkCoin = (store:any,data:object) => {
    return http({path: `/web/dcpay/topUp/checkCoin`, method: 'get',params:data}).then(res => {
        return res
    })
}

// 注销会员
export const cancelMsg = () => {
    return http({path: `/app/vip/cancellMsg`, method: 'get'}).then(res => {
        return res
    })
}
// 注销会员
export const memberEnd = () => {
    return http({path: `/app/order/stripe/member/end`, method: 'post'}).then(res => {
        return res
    })
}

// 检查支付状态
export const checkOrder = (store:any,orderId:string) => {
    return http({path: `/web/member/charge/check/${orderId}`, method: 'get'}).then(res => {
        return res
    })
}

// 验证邮箱
export const verifyEmail = (store:any,data:object) => {
    return http({path: `/web/index/validCode`, method: 'get', params:data}).then(res => {
        return res
    })
}

// 获取邮箱验证码
export const sendEmail = (store:any,data:object) => {
    return http({path: `/web/index/sendEmail`, method: 'get', params:data}).then(res => {
        return res
    })
}

// 获取邮箱验证码
export const getEmailCode = (store:any,data:object) => {
    return http({path: `web/index/sendValidCode`, method: 'get',params:data}).then(res => {
        return res
    })
}
// 获取邮箱验证码
export const sendEmailCode = (store:any,data:object) => {
    return http({path: `web/index/sendEmailCode`, method: 'get',params:data}).then(res => {
        return res
    })
}

// 修改密码
export const resetPassword = (store:any,data:object) => {
    return http({path: `/web/index/reset`, method: 'post', params:data}).then(res => {
        return res
    })
}// 修改密码
export const resetPayPassword = (store:any,data:object) => {
    return http({path: `web/index/resetPayPassword`, method: 'post', params:data}).then(res => {
        return res
    })
}

// 注册用户
export const register = (store:any,data:object) => {
    return http({path: `/web/index/register`, method: 'post', params:data}).then(res => {
        return res
    })
}
// 注册用户
export const updateDiscordUser = (store:any,data:object) => {
    return http({path: `/web/index/updateDiscordUser`, method: 'post', params:data}).then(res => {
        return res
    })
}

// 用户认证
export const userVerify = (store:any,data:object) => {
    return http({path: `/web/index/webvierfiy`, method: 'post', params:data}).then(res => {
        return res
    })
}

// 用户注册
export const secondary = (store:any,data:{name:string,value:string}) => {
    return http({path: `/web/index/secondary?name=${data.name}&value=${data.value}`, method: 'post', params:data}).then(res => {
        return res
    })
}

// 更新用户
export const updateUser = (store:any,data:object) => {
    return http({path: `/web/index/updateUser`, method: 'post', params:data}).then(res => {
        return res
    })
}
// 获取地址
export const getAddressList = (store:any) => {
    store.commit(types.ADDRESS_LIST, null)
    return http({path: `/web/member/address/list`, method: 'get'}).then(res => {
        store.commit(types.ADDRESS_LIST, res)
        return res
    })
}

// 添加地址
export const addAddress = (store:any,data:object) => {
    store.commit(types.ADDRESS_LIST, null)
    return http({path: `/web/member/address/add`, method: 'post',params:data}).then(res => {
        store.commit(types.ADDRESS_LIST, res)
        return res
    })
}

// 删除地址
export const deleteAddress = (store:any,id:string) => {
    return http({path: `/web/member/address/${id}`, method: 'delete'}).then(res => {
        return res
    })
}
// 删除地址
export const deleteEvent = (store:any,id:string) => {
    return http({path: `/web/member/cancellEvent/${id}`, method: 'get'}).then(res => {
        return res
    })
}
// 添加地址
export const getEnrollList = (store:any,data:object) => {
    store.commit(types.ENROLL_LIST, null)
    http({path: `/web/member/enroll/list`, method: 'get',params:data}).then(res => {
        store.commit(types.ENROLL_LIST, res)
    })
}

// 添加地址
export const getOrderList = (store:any,data:object) => {
    store.commit(types.ORDER_LIST, null)
    http({path: `/web/member/order/list`, method: 'get',params:data}).then(res => {
        store.commit(types.ORDER_LIST, res)
    })
}

// 添加地址
export const getConsumeList = (store:any,data:{
    url:string,
    data:object
}) => {
    store.commit(types.CONSUME_LIST, null)
    http({path: data.url, method: 'get',params:data.data}).then(res => {
        store.commit(types.CONSUME_LIST, res)
    })
}

// 添加地址
export const getCouponList = (store:any,data:object) => {
    store.commit(types.COUPON_LIST, null)
    http({path: `/web/member/coupon/list`, method: 'get',params:data}).then(res => {
        store.commit(types.COUPON_LIST, res)
    })
}

export const redeemCoupon = (store:any,data:object) => {
    return http({path: `/web/member/coupon/add`, method: 'get',params:data})
}

// 添加地址
export const getBookingList = (store:any,data:object) => {
    store.commit(types.BOOKING_LIST, null)
    return http({path: `/web/member/storeBooking/list`, method: 'get',params:data}).then(res => {
        store.commit(types.BOOKING_LIST, res)
        return res
    })
}

// 添加地址
export const cancelBooking = (store:any,data:object) => {
    return http({path: `/app/booking/cancel`, method: 'get',params:data}).then(res => {
        return res
    })
}

export const addFranchise = (store:any,data:object) => {
    return http({path: `/web/bussiness/member/questionnaire/franchise/add`, method: 'post',params:data}).then(res => {
        return res
    })
}
// 获取邀请信息
export const getUserInvent = (store: any,data:object) => {
    return http({ path: `/app/client/task/userInvent`, method: "get",params:data }).then((res) => {
        return res;
    });
};

// 拳头授权
export const getRiotAuth = (store: any,data:object) => {
    return http({ path: `/web/sidekick/getAuthorize`, method: "get", params:data })
}

// 授权列表
export const getAuthList = (store: any,params:object) => {
    return http({ path: `web/sidekick/lol/user/list`, method: "get",params })
}

// 取消授权
export const cancelAuth = (store: any,data:object) => {
    return http({ path: `/web/sidekick/lol/user/delete`, method: "get",params:data })
}

// discord用户更新
export const handleDiscordUploadUser = (store: any,data:object) => {
    return http({ path: `/web/dcUser/updateDiscordUser`, method: "post",params:data })
}

// 校验密码
export const checkPassword = (store: any,data:object) => {
    return http({ path: `/web/index/checkPwd`, method: "post",params:data })
}
// 重设密码
export const resetPass = (store: any,data:object) => {
    return http({ path: `/web/index/updatePwd`, method: "post",params:data })
}

// 校验支付密码
export const checkPayPassword = (store: any,data:object) => {
    return http({ path: `/web/index/checkPin`, method: "post",params:data })
}

// 重设支付密码
export const resetPayPass = (store: any,data:object) => {
    return http({ path: `/web/index/updatePin`, method: "post",params:data })
}

// 修改用户资料
export const updateUserInfo = (store: any,data:object) => {
    return http({ path: `/web/h5/match/updateProfile`, method: "post",params:data })
}
