import * as actions from './actions'
import mutations from './mutations'
export default {
    namespaced: true,
    state:{
        storeDetail: null,
        storeList: null,
    },
    actions,
    mutations
}