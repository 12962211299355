<template>
  <div class="root">
    <div class="down">
      <p>Available now</p>
      <img alt="" src="@/assets/mobile/home/sidequest-app.png" />
      <van-button @click="handleDownload">Download Now</van-button>
    </div>
  </div>
</template>

<script setup>
/** 下载 */
const handleDownload = () => {
  // 判断客户端
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    window.open("https://apps.apple.com/cn/app/sidequest-hub/id1614945163");
  } else {
    window.open(
      "https://play.google.com/store/apps/details?id=uk.co.sidequest.wy&gl=DE",
    );
  }
};
</script>

<style lang="scss" scoped>
.root {
  padding: 24px 8vw;

  .down {
    position: relative;
    /* width: 100%; */
    /* height: 701px; */
    aspect-ratio: 1;
    background: url("@/assets/mobile/home/down.png") no-repeat;
    background-size: 100% 100%;
    font-size: 1rem;
    /* margin: 0 auto 78px; */

    p {
      padding: 24px 0 0 24px;
      text-align: left;
    }

    img {
      width: 50%;
      padding: 0 0 0 24px;
    }

    :deep(.van-button) {
      /* width: 256px; */
      /* height: 83px; */
      background: #ffc100;
      border-radius: 8px;
      position: absolute;
      bottom: 24px;
      left: 24px;
      font-size: 1rem;
    }
  }
}
</style>
