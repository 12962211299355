<template>
  <div class="create">
<!--    <step1 v-if="step===1" @handleNext="handleNext" @handleBack="handleClick"></step1>-->
    <step2 v-if="step===2" @handleSubmit="handleSubmit" @handleBack="handleClick"></step2>
  </div>
</template>

<script>
// import Step1 from "./step1";
import step2 from "./step2";
import {provide, ref} from "vue";
import {useStore} from "vuex";

export default {
  name: "index-create",
  components: {
    // Step1,
    step2},
  setup(props, {emit}) {
    const step = ref(2)
    const store = useStore()
    const btnState = ref(false)
    provide('btnState',btnState)
    /**
     * 下一步
     */
    // const handleNext = (data) => {
    //   step.value = 2
    //   step1Form = data
    //   console.log(step1Form)
    // }

    /**
     * 注册成功返回登录
     */
    const handleClick = () => {
      emit('handleSkip')
    }

    const handleSubmit = (data) => {
      btnState.value = true
      let birth = new Date(data.birth);
      let formData = Object.assign({...data})
      let day = birth.getDate() > 9 ? birth.getDate():('0' + birth.getDate())
      let month = (birth.getMonth() + 1) > 9 ? (birth.getMonth() + 1) : ('0' + (birth.getMonth() + 1))
      let year = birth.getFullYear()
      formData.birth = day + '/' + month + '/' + year
      store.dispatch('user/updateDiscordUser',formData)
          .then(res => {
            btnState.value = false
            if(res) {
              store.dispatch('http/setHttpState',{
                msg:'Profile updated successfully!',
                state:true,
                dialogVisible:true,
              })
              handleClick()
            }
          })
    }


    return {
      step,
      // handleNext,
      handleSubmit,
      handleClick
    }
  }
}
</script>

<style scoped lang="scss">
.create {
  width: 100%;
  height: 100%;
}
</style>
