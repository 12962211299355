<template>
  <div class="create">
    <step
      v-if="step === 0"
      @handleNext="handleStep"
      @handleBack="handleClick"
    ></step>
    <step1
      v-if="step === 1"
      @handleNext="handleNext"
      @handleBack="step = 0"
    ></step1>
    <step2
      v-if="step === 2"
      @handleSubmit="handleSubmit"
      @handleBack="step = 1"
    ></step2>
    {{ oldForm }}
  </div>
</template>

<script>
import Step from "./step";
import Step1 from "./step1";
import step2 from "./step2";
import { provide, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "index-create",
  components: { Step, Step1, step2 },
  setup(props, { emit }) {
    const step = ref(0);
    const store = useStore();
    let step1Form = {};
    let oldForm = ref({});
    provide("oldForm", oldForm);
    /**
     * 下一步
     */
    const handleStep = (data) => {
      step.value = 1;
      if (data.birth !== null && data.birth.indexOf("/") > 0) {
        let from = data.birth.split("/");
        let birthdays = new Date(from[2], from[1] - 1, from[0]);
        let d = new Date();
        data.differYear =
          d.getFullYear() -
          birthdays.getFullYear() -
          (d.getMonth() < birthdays.getMonth() ||
          (d.getMonth() == birthdays.getMonth() &&
            d.getDate() < birthdays.getDate())
            ? 1
            : 0);
        data.birth = birthdays;
      }
      oldForm.value = data;
    };
    /**
     * 下一步
     */
    const handleNext = (data) => {
      step.value = 2;
      step1Form = data;
    };

    /**
     * 注册成功返回登录
     */
    const handleClick = () => {
      emit("handleClick", "0");
    };

    const handleSubmit = (data) => {
      let birth = new Date(step1Form.birth);
      let formData = Object.assign({ ...step1Form, ...data });
      let day = birth.getDate() > 9 ? birth.getDate() : "0" + birth.getDate();
      let month =
        birth.getMonth() + 1 > 9
          ? birth.getMonth() + 1
          : "0" + (birth.getMonth() + 1);
      let year = birth.getFullYear();
      formData.birth = day + "/" + month + "/" + year;
      store.dispatch("user/updateUser", formData).then((res) => {
        if (res) handleClick();
      });
    };

    return {
      step,
      oldForm,
      handleStep,
      handleNext,
      handleClick,
      handleSubmit,
    };
  },
};
</script>

<style scoped lang="scss">
.create {
  width: 100%;
  height: 100%;
}
</style>
