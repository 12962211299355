<template>
  <div class="articles">
    <div class="title">
      <p class="title-label">Recommended Articles</p>
      <router-link to="/news">
        <el-button class="text-black">See All</el-button>
      </router-link>
    </div>
    <div class="scrollable">
      <div class="list" v-if="recommendedLinks && recommendedLinks.length">
        <div class="recommended-item">
          <router-link
            :to="recommendedLinks[0].url"
            :aria-label="recommendedLinks[0].title"
          >
            <img
              style="object-fit: contain; background-color: black"
              :src="recommendedLinks[0].image"
              :alt="recommendedLinks[0].title"
            />
            <p class="name">{{ recommendedLinks[0].title }}</p>
            <p class="news-content" v-html="recommendedLinks[0].content"></p>
            <div class="explore">
              <span>{{ recommendedLinks[0].author }}</span>
              <span></span>
              <span>
                Explore details
                <el-icon><Right /></el-icon>
              </span>
            </div>
          </router-link>
        </div>
        <div class="recommended-item">
          <router-link
            :to="recommendedLinks[1].url"
            :aria-label="recommendedLinks[1].title"
          >
            <img
              style="object-fit: contain; background-color: black"
              :src="recommendedLinks[1].image"
              :alt="recommendedLinks[1].title"
            />
            <div>
              <p class="name">
                {{ recommendedLinks[1].title }}
              </p>
              <div class="explore">
                <span>{{ recommendedLinks[1].author }}</span>
                <span></span>
                <span>
                  Explore details
                  <el-icon><Right /></el-icon>
                </span>
              </div>
            </div>
          </router-link>
        </div>
        <div class="recommended-item">
          <router-link
            :to="recommendedLinks[2].url"
            :aria-label="recommendedLinks[2].title"
          >
            <img
              style="object-fit: contain; background-color: black"
              :src="recommendedLinks[2].image"
              :alt="recommendedLinks[2].title"
            />
            <div>
              <p class="name">
                {{ recommendedLinks[2].title }}
              </p>
              <div class="explore">
                <span>{{ recommendedLinks[2].author }}</span>
                <span></span>
                <span>
                  Explore details
                  <el-icon><Right /></el-icon>
                </span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, toRaw } from "vue";
import { useStore } from "vuex";

export default {
  name: "recommended-articles",
  setup() {
    const store = useStore();
    const news = computed(() => store.state.home.news);

    onMounted(() => {
      store.dispatch("home/getNews");
    });

    const createSlug = (text) => {
      // 将所有内容转为小写
      let slug = text.toLowerCase();
      // 用短横线替换非单词字符，例如空格
      slug = slug.replace(/\s+/g, "-");
      // 删除所有非单词字符，使用正则表达式
      slug = slug.replace(/[^\w-]+/g, "");
      // 如果slug以短横线开头或结尾，则删除它
      slug = slug.replace(/^\-|\-$/g, "");
      return slug;
    };

    const recommendedLinks = computed(
      () =>
        news.value &&
        toRaw(news.value).map((v) => ({
          url: `/news/detail/${v.id}/${createSlug(v.title)}`,
          ...v,
        }))
    );

    const storeData = ref(null);

    return {
      news,
      recommendedLinks,
    };
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/base" as *;

.articles {
  padding: 30px 10.94vw;

  @include media("<tablet") {
    padding: 24px 0;
  }

  .scrollable {
    @include media("<tablet") {
      overflow: auto;
    }
  }

  .title {
    text-align: left;

    .title-label {
      flex: 1;
    }
  }

  .list {
    display: grid !important;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    text-align: left;
    padding: 50px 0;
    justify-content: space-between;

    @include media("<tablet") {
      display: flex !important;
      width: fit-content;
      padding: 24px 8vw;
    }

    .recommended-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      position: relative;

      @include media("<tablet") {
        width: 240px;
      }

      &:first-child {
        grid-row: span 2 / span 2;

        img {
          height: 470px;
          background: #bbbbbb;
          object-fit: cover;
          object-position: center;
          border-radius: 12px;
        }

        .name {
          overflow: unset;
          font-size: 2.5rem;
          line-height: 1.1;
          padding: 6px 0 10px;
          color: #ffffff;

          @include media("<tablet") {
            overflow: hidden;
            padding: unset;
            margin-bottom: unset;
            line-height: unset;
          }
        }

        .news-content {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        p {
          color: #959595;
          font-size: 0.9rem;
          margin-bottom: 20px;
        }
      }

      p {
        display: flex;
        justify-content: flex-start;
        font-size: 1.1rem;
      }

      a {
        width: 100%;
      }

      img {
        width: 100%;
        height: 276px;
        background: #bbbbbb;
        object-fit: cover;
        object-position: center;
        border-radius: 12px;

        @include media("<tablet") {
          max-width: 240px !important;
          height: unset !important;
          aspect-ratio: 16/9;
        }
      }

      .name {
        font-size: 1.3rem;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media("<tablet") {
          display: block;
          font-size: 1.1rem !important;
          text-overflow: ellipsis !important;
          white-space: nowrap;
        }
      }

      .explore {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 10px;

        @include media("<tablet") {
          display: none;
        }

        span {
          font-size: 0.85rem;
        }

        span:nth-child(2) {
          width: 2px;
          background: #ffd40e;
          height: 10px;
          margin: 0 10px;
        }

        span:last-child {
          color: #ffd40e;
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }
}
</style>
