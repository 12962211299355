import * as types from './mutation_types'
import http from "@/utils/request";

export const getNewsList = (store:any,data:object) => {
    store.commit(types.NEWS_LIST, null)
    return http({path: '/web/index/news/newweb/index', method: 'get',params:data}).then(res => {
        store.commit(types.NEWS_LIST, res)
        return res
    })
}

export const getNewsDetail = (store:any,id:string) => {
    store.commit(types.NEWS_DETAIL, null)
    return http({path: `/web/index/news/detail/${id}`, method: 'get'}).then(res => {
        store.commit(types.NEWS_DETAIL, res)
        return res
    })
}
export const getWebNewsDetail = (store:any,id:string) => {
    store.commit(types.NEWS_DETAIL, null)
    return http({path: `/web/index/news/newsiteNewsDetail/${id}`, method: 'get'}).then(res => {
        store.commit(types.NEWS_DETAIL, res)
        return res
    })
}
