<template>
  <div class="index">
    <p class="header">Log in</p>
    <p class="sub-header">
      Don't have an account?
      <span
        class="text-[#FFB20E]"
        style="cursor: pointer"
        @click="handleClick('1')"
        >Create one!</span
      >
    </p>

    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" class="form">
      <el-form-item prop="username">
        <el-input
          v-model="ruleForm.username"
          autocomplete="off"
          placeholder="Sidequest ID or Email"
        />
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="ruleForm.password"
          type="password"
          autocomplete="new-password"
          placeholder="Password"
        />
      </el-form-item>
      <el-form-item class="btn">
        <el-button type="primary" class="signin" @click="onSubmit"
          >Sign in</el-button
        >
        <el-button
          type="primary"
          class="create font-bold"
          @click="handleClick('1')"
          >Create account</el-button
        >
      </el-form-item>
    </el-form>
    <div class="last-btn">
      <p @click="handleClick('2')">Forget your password？</p>
      <p @click="handleClick('3')">Verify your Wanyoo account</p>
      <!--      <a style="color: #979DAD;float: right;font-size: 16PX" href="https://discord.com/api/oauth2/authorize?client_id=1043016152168792094&redirect_uri=https%3A%2F%2Fsidequesthub.com%2Fproxy%2Fweb%2Fextra%2FaccessToken&response_type=code&scope=identify%20email">Login with Discord</a>-->
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import http from "@/utils/request.ts";
import { useStore } from "vuex";

export default {
  name: "index-login",
  setup(props, { emit }) {
    const store = useStore();
    const ruleFormRef = ref(null);

    // form规则
    const rules = reactive({
      username: [{ required: true, message: "name is required" }],
      password: [{ required: true, message: "password is required" }],
    });
    // form数据
    const ruleForm = reactive({
      username: "",
      password: "",
    });

    /**
     * 登录提交
     */
    const onSubmit = async () => {
      await ruleFormRef.value.validate((valid) => {
        if (valid) {
          http({
            path: "/web/index/weblogin",
            method: "post",
            params: ruleForm,
          }).then((res) => {
            if (res) {
              sessionStorage.setItem("Authorization", res.token);
              store.dispatch("user/setUserData", res);
              store.dispatch("shop/getCartList");
              emit("handleClose");
            }
          });
        }
      });
    };

    /**
     * 切换（注册，老用户，忘记密码）
     * @param v
     */
    const handleClick = (v) => {
      emit("handleClick", v);
    };
    return {
      ruleForm,
      rules,
      onSubmit,
      handleClick,
      ruleFormRef,
    };
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/base" as *;

.index {
  max-width: 500px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  padding: 0 24px;
  color: #ffffff;

  @include media("<tablet") {
    width: 100%;
    padding: 24px;
    top: 0;
    left: 0;
    transform: unset;
    height: fit-content;
  }

  .header {
    @include text-4xl;
    @include font-bold;
  }

  .sub-header {
    @include text-xl;
    color: #808080;
    padding: 20px 0;

    @include media("<tablet") {
      @include text-lg;
    }
  }

  :deep(.form) {
    display: flex;
    flex-direction: column;
    gap: 38px;
    margin-bottom: 18px;

    .el-form-item {
      margin-bottom: unset;
    }

    .el-form-item__error {
      @include text-base;
    }

    .el-form-item__content {
      display: flex;
      gap: 20px;
    }

    .el-button {
      @include text-xl;
      @include button-base;
      width: 216px;
      height: 55px;

      &.signin {
        color: white;
      }

      &.create {
        color: #272c38;
        margin-left: unset;
        background: #ffb20e;
        border-color: #ffb20e;
      }

      @include media("<tablet") {
        width: 100%;
        font-size: 1.2rem;
        margin-right: unset;

        &:first-child {
          margin-top: unset;
        }
      }
    }

    .btn {
      background: none;
      color: #ffffff;
    }

    .el-input {
      border: none;

      .el-input__wrapper {
        width: 460px;
        height: 70px;
        background: #272c38;
        border-radius: 12px;
        border: none;

        .el-input__inner {
          @include text-lg;
          width: 100%;
          height: 100%;
          color: #ffffff;
          background-color: #272c38;
        }
      }
    }
  }

  .last-btn {
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 20px;

    p {
      @include text-base;
      text-align: right;
      color: #979dad;
      cursor: pointer;
    }
  }
}
</style>
