import * as types from "./mutation_types"

export default {
    [types.SHOP_LIST] (state:any,data={
        category: Array,
        images: Array,
        footbanner: Array,
    }) {
        state.shopList = data!=null&&data.category
        state.bannerList = data!=null&&data.images
        state.footBanner = data!=null&&data.footbanner
    },
    [types.SHOP_DETAIL](state:any,data:object){
        state.shopDetail = data!=null&&data
    },
    [types.CART_LIST](state:any,data:any){
        state.cartList = data!=null&&data
        if(data){
            const cartData = {
                goodsNum:0,
                totalMoney:0,
                totalTax:0
            }
            data.forEach((item:any) => {
                cartData.goodsNum += item.number
                cartData.totalMoney += (item.number * Number(item.price))
                cartData.totalTax += (item.number * Number(item.tax))

            })
            state.cartData = cartData
        }
    },
    [types.CART_STEP](state:any,data:number){
        state.cartStep = data
    },
    [types.ORDER_DATA](state:any,data:any){
        if(data){
            const cartData = {
                goodsNum:0,
                totalMoney:0,
                totalTax:0
            }
            console.log(data)
            data.forEach((item:any) => {
                cartData.goodsNum += item.number
                cartData.totalMoney += (item.number * Number(item.price))
                cartData.totalTax += (item.number * Number(item.tax))

            })
            state.orderData = cartData
        }
    }
}
