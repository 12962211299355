import * as types from './mutation_types'
import http from "@/utils/request";

export const getShpwData = (store:any,data:{id:string,dcid:string}) => {
    store.commit(types.SHPW_DATA, null)
    return http({path: `/web/peiwan/player/${data.id}`, method: 'get',params:{dcid:data.dcid}}).then(res => {
        store.commit(types.SHPW_DATA, res)
        return res
    })
}
export const getPreOrder = (store:any,data:{ skillAuthid:string, serviceItemId:string,id:string}) => {
    store.commit(types.PRE_ORDER_DATA, null)
    return http({path: `/web/peiwan/preOrder?skillAuthId=${data.skillAuthid}&serviceItemId=${data.serviceItemId}&liveuid=${data.id}`, method: 'get'}).then(res => {
        store.commit(types.PRE_ORDER_DATA, res)
        return res
    })
}
export const setPreOrder = (store:any,data:{ skillAuthid:string,data:object}) => {
    return http({path: `/web/peiwan/calculate`, method: 'post',params:data}).then(res => {
        return res
    })
}
export const handlePayment = (store:any,data:{ skillAuthid:string,data:object}) => {
    return http({path: `/web/peiwan/setorder`, method: 'post',params:data}).then(res => {
        return res
    })
}

// 修改密码
export const resetPaymentPin = (store:any,data:object) => {
    return http({path: `/web/peiwan/resetPayPassword`, method: 'post', params:data}).then(res => {
        return res
    })
}