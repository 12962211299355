import * as types from './mutation_types'
import http from "@/utils/request";

export const getHomeList = (store:any) => {
    store.commit(types.HOME_DATA, null)
    return http({path: '/web/index/all', method: 'get'}).then(res => {
        store.commit(types.HOME_DATA, res)
        return res
    })
}
export const getAdList = (store:any) => {
    store.commit(types.HOME_DATA, null)
    return http({path: '/web/index/adList', method: 'get'}).then(res => {
        store.commit(types.HOME_DATA, {data:res,type:'adList'})
        return res
    })
}
export const getGameList = (store:any) => {
    store.commit(types.HOME_DATA, null)
    return http({path: '/web/index/gameList', method: 'get'}).then(res => {
        store.commit(types.HOME_DATA, {data:res,type:'gameList'})
        return res
    })
}

export const getHeadline = (store:any) => {
    store.commit(types.HOME_DATA, null)
    return http({path: '/web/index/headline', method: 'get'}).then(res => {
        store.commit(types.HOME_DATA, {data:res,type:'headline'})
        return res
    })
}
export const getMatch = (store:any) => {
    store.commit(types.HOME_DATA, null)
    return http({path: '/web/index/match', method: 'get'}).then(res => {
        store.commit(types.HOME_DATA, {data:res,type:'match'})
        return res
    })
}
export const getNews = (store:any) => {
    store.commit(types.HOME_DATA, null)
    return http({path: '/web/index/news', method: 'get'}).then(res => {
        store.commit(types.HOME_DATA, {data:res,type:'news'})
        return res
    })
}
export const getStoreAreaList = (store:any) => {
    store.commit(types.HOME_DATA, null)
    return http({path: '/web/index/storeAreaList', method: 'get'}).then(res => {
        store.commit(types.HOME_DATA, {data:res,type:'storeAreaList'})
        return res
    })
}
export const getStoreList = (store:any) => {
    store.commit(types.HOME_DATA, null)
    return http({path: '/web/index/storesList', method: 'get'}).then(res => {
        store.commit(types.HOME_DATA, {data:res,type:'storeList'})
        return res
    })
}
export const addBooking = (store:any,data:object) => {
    return http({path: '/web/index/store/booking', method: 'post',params:data}).then(res => {
        return res
    })
}
export const setLoginStatus = (store:any,data:boolean) => {
    store.commit(types.LOGIN_STATUS, data)
}
