import * as actions from "./actions";
import mutations from "./mutations";

export default {
	namespaced: true,
	state: {
		uploadData: null,
		fullScreenImg: "",
		imgDisplay: false,
	},
	actions,
	mutations,
};
