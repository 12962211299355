import * as types from "./mutation_types"

export default {
    [types.GAME_LIST] (state:any,data={
        games: Object,
        banners: Object
    }) {
        console.log(data)
        state.gameList = data!=null&&data.games?data.games:data
        if(data!=null&&data.banners){
            state.bannerList = data!=null&&data.banners
        }
    },
}
