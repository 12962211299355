import * as types from './mutation_types'
import http from "@/utils/request";

export const getStoreDetail = (store:any,id:string) => {
    store.commit(types.STORE_DETAIL, null)
    return http({path: `/web/shop/goods/${id}`, method: 'get'}).then(res => {
        store.commit(types.STORE_DETAIL, res)
        return res
    })
}
export const getStoreList = (store:any) => {
    store.commit(types.STORE_LIST, null)
    return http({path: `/web/index/store/listwebstore`, method: 'get'}).then(res => {
        store.commit(types.STORE_LIST, res)
        return res
    })
}

export const getWebStoreDetail = (store:any,id:string) => {
    store.commit(types.STORE_DETAIL, null)
    return http({path: `/web/index/store/${id}`, method: 'get'}).then(res => {
        store.commit(types.STORE_DETAIL, res)
        return res
    })
}
